import { connect } from 'react-redux';
import Component from './Component';
import { handleSubmit, getSupportedFrequency } from './action';

const mapStateToProps = ({ loading, userAccountMapping, supportedFrequency }) => ({
  loading,
  userAccountMapping,
  supportedFrequency
});

const mapDispatchToProps = { handleSubmit, getSupportedFrequency };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
