import { gql } from '@apollo/client';

const GET_MONTHLY_WORM = gql`
  query {
    monthlyWorm {
      year
      monthlyData {
        month
        salesTotal
        orderCount
      }
    }
  }
`;

export { GET_MONTHLY_WORM };
