import { Typography, Box, Stack, Divider } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(1),
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.16)
}));

export function SectionHeading({ title, icon, subtitle, ...restProps }) {
  const theme = useTheme();
  return (
    <Box sx={{ mb: theme.spacing(4), mt: theme.spacing(5) }} {...restProps}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        {icon && <IconWrapperStyle>{icon}</IconWrapperStyle>}
        <Box>
          <Typography variant="h4" color="text.primary">
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="subtitle2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
export function PageTitle({ children, ...restProps }) {
  const theme = useTheme();
  return (
    <Typography variant="h3" component="h1" color="text.primary" mb={theme.spacing(5)} {...restProps}>
      {children}
    </Typography>
  );
}
export function SubSectionHeading({ title, subtitle, icon, ...restProps }) {
  return (
    <Box sx={{ pt: 1, pb: 1 }}>
      <Stack direction="row">
        {icon && <IconWrapperStyle>{icon}</IconWrapperStyle>}
        <Box>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="subtitle2" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
}
export function ChartTitle({ children, ...restProps }) {
  const theme = useTheme();
  return (
    <Typography
      variant="subtitle1"
      color="text.secondary"
      sx={{ fontWeight: theme.typography.fontWeightRegular }}
      {...restProps}
    >
      {children}
    </Typography>
  );
}

export function CardTitle({ children, ...restProps }) {
  const theme = useTheme();
  return (
    <Typography variant="h5" color="text.primary" sx={{ fontWeight: theme.typography.fontWeightMedium }} {...restProps}>
      {children}
    </Typography>
  );
}
