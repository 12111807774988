import { gql } from '@apollo/client';

const GET_TOP_CATEGORIES = gql`
  query {
    topCategories {
      topBySales {
        name
        salesTotal
      }
      topByOrderCount {
        name
        orderCount
      }
      topByAov {
        name
        aov
      }
    }
  }
`;

const GET_CATEGORY_SALES_DIST = gql`
  query ($id: String, $from_date: String, $to_date: String) {
    categorySalesDistribution(id: $id, from_date: $from_date, to_date: $to_date) {
      id
      label
      salesTotal
      orderCount
      aov
    }
  }
`;
const GET_PRODUCTS_IN_CATEGORY = gql`
  query ($id: String) {
    productsInCategory(id: $id) {
      id
      product {
        name
        price
      }
      category {
        name
      }
      orderCount
      inventoryQuantity
    }
  }
`;

export { GET_TOP_CATEGORIES, GET_CATEGORY_SALES_DIST, GET_PRODUCTS_IN_CATEGORY };
