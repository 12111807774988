import axios from 'axios';
import useAxios from '../hooks/useAxios';
import { startLoading, stopLoading } from '../redux/slices/loading';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  responseEncoding: 'utf8'
});

export default axiosInstance;
export const getRequest = async (url) => {
  const response = await axios.get(url);
  return response;
};
export const postRequest = async (url) => {
  const response = await axios.post(url);
  return response;
};

// export const getRequest = (url, params, extraHeader, isLoading) => (dispatch) => {
//  console.log('--------Initiating GET req ', url);
//  const config = {
//    headers: {
//      ...extraHeader
//    },
//    params,
//    withCredentials: true
//  };
//  if (!isLoading) {
//    dispatch(startLoading());
//  }
//  return axios
//    .get(url, config)
//    .then((response) => {
//      if (!isLoading) {
//        dispatch(stopLoading());
//      }
//      console.log('--------Response GET req ', url, response?.data);
//      return response.data;
//    })
//    .catch((error) => {
//      console.log('--------Error GET req ', url, error?.response?.data);
//
//      if (!isLoading) {
//        dispatch(stopLoading());
//      }
//      throw error?.response?.data;
//    });
// };
//
// export const postRequest = (url, body, extraHeader, params, isLoading) => (dispatch) => {
//  console.log('--------Initiating POST req ', url);
//  const config = {
//    headers: {
//      ...extraHeader
//    },
//    params,
//    withCredentials: true
//  };
//  if (!isLoading) {
//    dispatch(startLoading());
//  }
//
//  return axiosInstance
//    .post(url, body, config)
//    .then((resp) => {
//      if (!isLoading) {
//        dispatch(stopLoading());
//        return resp.data;
//      }
//    })
//    .catch((err) => {
//      if (!isLoading) {
//        dispatch(stopLoading());
//      }
//      throw err?.response?.data;
//    });
// };
