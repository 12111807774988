import {
  CONNECT_TO_XERO,
  GET_CONNECTION,
  CONNECT_TO_QUICKBOOKS,
  CONNECT_TO_TALLY,
  CONNECT_TO_ETSY
} from '../../../../../backendUrl/apiUrl';
import { postRequest, getRequest } from '../../../../../utils/axios';
import { setConnection } from '../../../../../redux/slices/connection';
import { showShopNameModal } from '../../../../../redux/slices/shopify/popup';
import { showStoreNameModal } from '../../../../../redux/slices/woocommerce/popup';
import { showAmazonCountryModal } from '../../../../../redux/slices/amazon/popup';
import { AppName } from '../../../../../config';
import { startLoading, stopLoading } from '../../../../../redux/slices/loading';

export const handleAppConnect = (supportedConnection, appName, appId) => (dispatch, getState) => {
  let SERVICE_URL = null;
  const { userInfo } = getState();

  switch (appName) {
    case AppName.AMAZON:
      dispatch(showAmazonCountryModal());
      break;
    case AppName.XERO:
      SERVICE_URL = CONNECT_TO_XERO;
      break;
    case AppName.QUICKBOOKS:
      SERVICE_URL = CONNECT_TO_QUICKBOOKS;
      break;

    case AppName.TALLY:
      dispatch(connectTally(supportedConnection, appId));
      break;
    case AppName.SHOPIFY:
      dispatch(showShopNameModal());
      return;
    case AppName.WOOCOMMERCE:
      dispatch(showStoreNameModal());
      return;
    case AppName.ETSY:
      SERVICE_URL = CONNECT_TO_ETSY;
      return;
    default:
      SERVICE_URL = null;
  }
  if (SERVICE_URL) {
    const params = {
      user_id: userInfo._id,
      supported_connection_id: supportedConnection._id,
      app_id: appId
    };
    dispatch(startLoading());
    return dispatch(getRequest(SERVICE_URL, params, {}, true))
      .then((res) => {
        window.open(res, '_self');
        dispatch(stopLoading());
      })
      .catch((error) => {
        console.log('no valid app found', error);
      });
  }
};

export const connectTally = (supportedConnection, appId) => (dispatch, getState) => {
  const { userInfo } = getState();
  const body = {
    user_id: userInfo._id,
    supported_connection_id: supportedConnection._id,
    app_id: appId
  };

  return dispatch(postRequest(CONNECT_TO_TALLY, body)).then((res) => {
    dispatch(getAppConnection(supportedConnection));
  });
};

export const getAppConnection = (supportedConnection) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id,
    supported_connection_id: supportedConnection._id
  };
  return dispatch(getRequest(GET_CONNECTION, params)).then((res) => {
    dispatch(setConnection(res.data.connection));
  });
};
