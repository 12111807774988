import { connect } from 'react-redux';
import Component from './Component';
import { handleWoocommerceConnect } from './action';
import { hideStoreNameModal } from '../../../../../../../redux/slices/woocommerce/popup';

const mapStateToProps = ({ woocommercePopup: { isWoocommerceStoreurlVisible } }) => ({ isWoocommerceStoreurlVisible });

const mapDispatchToProps = { handleWoocommerceConnect, hideStoreNameModal };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
