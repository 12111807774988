import { Box, Typography, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie-player';
import { useTable } from 'react-table';
import { useEffect, useMemo, useState } from 'react';
import Home from '@mui/icons-material/Home';

import { PATH_DASHBOARD } from '../../../../../routes/paths';
import './table.css';

function Table({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default function ConnectionSuccess({ getConnectionDetails }) {
  const navigate = useNavigate();
  const [accountMappingDetails, setAccountMappingDetails] = useState([]);
  const [connectionDetails, setConnectionDetails] = useState([]);
  const {
    state: { supportedConnection, connection }
  } = useLocation();

  useEffect(() => {
    getConnectionDetails(supportedConnection, connection).then((res) => {
      const accountMapping = res?.accountMapping?.account_mapping?.map((account) => {
        const tTypeObj = res?.transactionType?.transaction_types?.find(
          (tType) => account?.transactionTypeID === tType?.id
        );
        return {
          transactionType: tTypeObj,
          account
        };
      });
      setAccountMappingDetails(accountMapping);
      const connectionDtls = [];
      connectionDtls.push({
        ...res.connection,
        ...supportedConnection
      });
      setConnectionDetails(connectionDtls);
    });
  }, []);

  const columns1 = useMemo(
    () => [
      {
        // first group - TV Show
        Header: 'Connection Details',
        // First group columns
        columns: [
          {
            Header: 'From App Name',
            accessor: 'from_app_name'
          },
          {
            Header: 'To App Name',
            accessor: 'to_app_name'
          },
          {
            Header: 'Frequency',
            accessor: 'app_cron_frequency'
          }
        ]
      }
    ],
    []
  );

  const columns2 = useMemo(
    () => [
      {
        // first group - TV Show
        Header: 'Mapping Details',
        // First group columns
        columns: [
          {
            Header: 'Transaction Type',
            accessor: 'transactionType.name'
          },
          {
            Header: 'Account Name',
            accessor: 'account.accountInfo.Name'
          },
          {
            Header: 'Account Code',
            accessor: 'account.accountInfo.Code'
          }
        ]
      }
    ],
    []
  );

  return (
    <Box maxWidth={610} sx={{ margin: 'auto', padding: 3 }}>
      <div style={{ position: 'absolute', top: 90 }}>
        <Home
          color="primary"
          sx={{ mt: 2 }}
          onClick={() => {
            navigate(PATH_DASHBOARD.general.app);
          }}
        />
      </div>
      <Stack direction="row" justifyContent="center" alignIems="center">
        <Lottie
          loop
          path="https://assets2.lottiefiles.com/packages/lf20_owom3yeb.json"
          play
          rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
          style={{ width: 120, height: 120 }}
        />
      </Stack>

      <Typography variant="h6" style={{ textAlign: 'center', mt: 2 }}>
        {`Congratulation your connection between ${connectionDetails[0]?.from_app_name} and ${connectionDetails[0]?.to_app_name} has been set up successfully`}
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Table columns={columns1} data={connectionDetails} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Table columns={columns2} data={accountMappingDetails} />
      </Box>
    </Box>
  );
}
