import { createSlice } from '@reduxjs/toolkit';

const initialState = { analysisdata: [0, [0], 0, 0, 0, 0, '', '', 0] };

const slice = createSlice({
  name: 'analysisdata',
  initialState,
  reducers: {
    setanalysisdata: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setanalysisdata } = slice.actions;
