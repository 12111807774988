import { getRequest, postRequest } from '../../../../../utils/axios';
import {
  CREATE_ACCOUNT_MAPPING,
  GET_TRANSACTION_TYPES,
  GET_XERO_ACCOUNTS,
  GET_QUICKBOOKS_ACCOUNTS,
  GET_USER_ACCOUNT_MAPPING
} from '../../../../../backendUrl/apiUrl';
import { setConnectionAccountMapping } from '../../../../../redux/slices/connectionAccountMapping';
import { setuserAccountMapping } from '../../../../../redux/slices/userAccountMapping';
import { showCreateAccountModal } from '../../../../../redux/slices/createAccount';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { startLoading, stopLoading } from '../../../../../redux/slices/loading';
import { APP_IDS, AppName } from '../../../../../config';

export const getConnectionMappingList = (supportedConnection) => (dispatch, getState) => {
  const { userInfo } = getState();
  const accountsReq = {
    user_id: userInfo._id,
    app_id: supportedConnection.to_app_id
  };
  const transactionTypesReq = {
    user_id: userInfo._id,
    app_id: supportedConnection.from_app_id
  };
  let GET_ACCOUNTS = null;

  switch (supportedConnection?.to_app_id) {
    case APP_IDS.XERO:
      GET_ACCOUNTS = GET_XERO_ACCOUNTS;
      break;
    case APP_IDS.QUICKBOOKS:
      GET_ACCOUNTS = GET_QUICKBOOKS_ACCOUNTS;
      break;

    default:
      GET_ACCOUNTS = GET_XERO_ACCOUNTS;
  }

  return Promise.all([
    dispatch(getRequest(GET_TRANSACTION_TYPES, transactionTypesReq, {}, true)),
    dispatch(getRequest(GET_ACCOUNTS, accountsReq, {}, true))
  ])
    .then((res) => {
      dispatch(
        setConnectionAccountMapping({
          transactionTypes: res[0].data?.transactionTypes,
          accounts: res[1].data?.accounts
        })
      );
      dispatch(stopLoading());
    })
    .catch(() => {
      dispatch(setConnectionAccountMapping({ transactionTypes: [], accounts: [] }));
      dispatch(stopLoading());
    });
};

export const showCreateAccount = (supportedConnection) => (dispatch) => {
  switch (supportedConnection?.to_app_id) {
    case APP_IDS.XERO:
      dispatch(showCreateAccountModal(AppName.XERO.toLowerCase()));
      break;
    case APP_IDS.QUICKBOOKS:
      dispatch(showCreateAccountModal(AppName.QUICKBOOKS.toLowerCase()));
      break;
    default:
      break;
  }
};

export const handleSubmit = (mappingDetails, connection, supportedConnection, navigate) => (dispatch, getState) => {
  const { userInfo } = getState();
  const req = {
    connection_id: connection._id,
    account_mapping: mappingDetails,
    user_id: userInfo._id
  };
  return dispatch(postRequest(CREATE_ACCOUNT_MAPPING, req))
    .then((res) => {
      navigate(PATH_DASHBOARD.general.configureCronFrequency, {
        state: {
          supportedConnection,
          connection
        }
      });
    })
    .catch((error) => {
      console.error('CREATE_ACCOUNT_MAPPING error', error);
    });
};

export const getUserAccountMapping = (connection, supportedConnection) => (dispatch, getState) => {
  const { userInfo } = getState();
  console.log('connection......', connection);
  const params = {
    user_id: userInfo?._id,
    connection_id: connection?._id
  };
  dispatch(setuserAccountMapping({ mapping: [], appCronFrequency: '' }));
  dispatch(startLoading());
  return dispatch(getRequest(GET_USER_ACCOUNT_MAPPING, params, {}, true))
    .then((res) => {
      dispatch(getConnectionMappingList(supportedConnection));
      dispatch(
        setuserAccountMapping({ mapping: res?.data?.userAccountMapping, appCronFrequency: res?.data?.appCronFrequency })
      );
    })
    .catch(() => {
      dispatch(setuserAccountMapping({ mapping: [], appCronFrequency: '' }));
      dispatch(stopLoading());
      console.error('exectiopn occurred getUserAccountMapping ');
    });
};
