import { gql } from '@apollo/client';

const GET_OVERALL_WEEKLY_RETENTION = gql`
  query ($fromDate: String, $toDate: String) {
    overallWeeklyRetention(fromDate: $fromDate, toDate: $toDate) {
      dateObj {
        week
        year
      }
      customerCount
      customerPercent
    }
  }
`;
const GET_TEMPORAL_COHORT_RETENTION = gql`
  query ($fromDate: String, $toDate: String) {
    temporalCohortRetention(fromDate: $fromDate, toDate: $toDate) {
      dateObj {
        week
        year
      }
      acquiredCustomers
      retentionDistribution {
        dateObj {
          week
          year
        }
        customerCount
        customerPercent
      }
    }
  }
`;

export { GET_OVERALL_WEEKLY_RETENTION, GET_TEMPORAL_COHORT_RETENTION };
