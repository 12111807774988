import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Stack, Typography, CircularProgress } from '@mui/material';
import useAxios from '../../../../../hooks/useAxios';
import { CONNECT_TO_ETSY } from '../../../../../backendUrl/apiUrl';

export default function RedirectToEtsy() {
  const { appId } = useParams();
  const axiosInstance = useAxios();
  useEffect(async () => {
    await axiosInstance
      .get(CONNECT_TO_ETSY)
      .then((resp) => {
        window.open(resp.data?.authorizationUrl, '_self');
      })
      .catch((err) => console.log('error in authorizationUrl', err));
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 4,
        width: '100%',
        height: 300,
        backgroundColor: 'primary.lighter'
      }}
    >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color="success" />
        </Box>
        <Typography variant="subtitle1" color="primary.dark" sx={{ mt: 2 }}>
          Redirecting you to ETSY
        </Typography>
      </Stack>
    </Box>
  );
}
