import React, { createContext } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';

export const AxiosContext = createContext();

export const AxiosProvider = ({ children }) => {
  const { getAccessTokenSilently, user } = useAuth();
  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      'X-Origin-Host': window.location.host
    },
    withCredentials: true
  });
  axiosInstance.interceptors.request.use(
    async (config) => {
      const token = await getAccessTokenSilently();
      config.headers.Authorization = `Bearer ${token}`;
      config.headers.Userdata = JSON.stringify(user);
      return config;
    },
    (error) => Promise.reject(error)
  );

  return <AxiosContext.Provider value={axiosInstance}>{children}</AxiosContext.Provider>;
};
