import { PlanStarterIcon, PlanPremiumIcon, PlanFreeIcon } from './assets';
import { ROOT_URL } from './backendUrl/apiUrl';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId:
    process.env.REACT_APP_ENV === 'DEV'
      ? process.env.REACT_APP_AUTH0_CLIENT_ID
      : process.env.REACT_APP_AUTH0_CLIENT_ID_PROD,
  domain:
    process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_AUTH0_DOMAIN : process.env.REACT_APP_AUTH0_DOMAIN_PROD,
  redirectUri:
    process.env.REACT_APP_ENV === 'DEV' ? process.env.REACT_APP_AUTH0_REDIRECT_URI : `${ROOT_URL}/auth/callback`,
  audience:
    process.env.REACT_APP_ENV === 'DEV'
      ? process.env.REACT_APP_AUTH0_AUDIENCE
      : process.env.REACT_APP_AUTH0_AUDIENCE_PROD
};
// export const auth0Config = {
//  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID_PROD,
//  domain: process.env.REACT_APP_AUTH0_DOMAIN_PROD,
//  redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
//  audience: process.env.REACT_APP_AUTH0_AUDIENCE_PROD
// };

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;

export const stripeConfig = {
  publishableKey:
    process.env.REACT_APP_ENV === 'DEV'
      ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
      : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_PROD
};

export const AppName = {
  XERO: 'XERO',
  QUICKBOOKS: 'QUICKBOOKS',
  AMAZON: 'AMAZON',
  TALLY: 'TALLY',
  SHOPIFY: 'SHOPIFY',
  ETSY: 'ETSY',
  WOOCOMMERCE: 'WOOCOMMERCE'
};

export const AppConnectionStatus = {
  To: 'To',
  From: 'From',
  Both: 'Both'
};

export const APP_IDS = {
  AMAZON: '6302122b94019b7d38146bec',
  XERO: '6302125394019b5c3a146bee',
  SHOPIFY: '6302127194019baa3d146bf0',
  QUICKBOOKS: '6302128394019b1da4146bf2',
  TALLY: '6302129794019be8a2146bf4',
  ETSY: '630212c394019b0520146bf6',
  WOOCOMMERCE: '630211f094019b518b146bea'
};

export const Analyticsconstants = {
  UsdToINR: 79,
  FromDate: '1920-04-24',
  COD: 'cod',
  OP: 'op',
  CODS: 'cash_on_delivery',
  CODS1: 'Cash on Delivery',
  none: 'none',
  orders: 'orders (quantity)'
};

export const getPlanIcon = {
  1: <PlanFreeIcon />,
  2: <PlanStarterIcon />,
  3: <PlanPremiumIcon />,
  4: <PlanPremiumIcon />
};

export const subscriptionStatus = {
  active: 'active',
  cancelled: 'cancelled',
  failed: 'failed',
  trialing: 'trialing'
};

export const planName = {
  Free: 'Free',
  Basic: 'Basic',
  Premium: 'Premium'
};
