import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isShow: false,
  data: {}
};

const slice = createSlice({
  name: 'confirmPopup',
  initialState,
  reducers: {
    showConfirmationPopup: (state, action) => ({
      ...state,
      isShow: true,
      data: action.payload
    }),
    hideConfirmationPopup: () => initialState
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showConfirmationPopup, hideConfirmationPopup } = slice.actions;
