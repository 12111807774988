import { useState, createContext } from 'react';
import dayjs from 'dayjs';

export const DateContext = createContext();

export const DateProvider = ({ children }) => {
  const [myDayRange, setMyDayRange] = useState([dayjs().subtract(7, 'day'), dayjs()]);
  const [myWeekRange, setMyWeekRange] = useState([dayjs().subtract(5, 'week').startOf('week'), dayjs()]);
  const [myMonthRange, setMyMonthRange] = useState([dayjs().subtract(3, 'month').startOf('month'), dayjs()]);
  return (
    <DateContext.Provider
      value={{ myDayRange, setMyDayRange, myWeekRange, setMyWeekRange, myMonthRange, setMyMonthRange }}
    >
      {children}
    </DateContext.Provider>
  );
};
