import { hideAmazonCountryModal } from '../../../../../../../redux/slices/amazon/popup';
import { GET_AMAZON_AUTH_URL, GET_URL_LIST } from '../../../../../../../backendUrl/apiUrl';
import { getRequest } from '../../../../../../../utils/axios';
import { setAmazonCountryUrls } from '../../../../../../../redux/slices/amazonCountryUrls';
import { startLoading, stopLoading } from '../../../../../../../redux/slices/loading';

export const handleAmazonConnect = (supportedConnection, appId, countryObj) => (dispatch, getState) => {
  if (!countryObj?.country_name) return;
  const { userInfo } = getState();
  dispatch(hideAmazonCountryModal());
  const params = {
    user_id: userInfo._id,
    supported_connection_id: supportedConnection._id,
    app_id: appId,
    country: countryObj,
    state: process.env.REACT_APP_STATE
  };
  dispatch(startLoading());
  dispatch(getRequest(GET_AMAZON_AUTH_URL, params, {}, true)).then((res) => {
    window.open(res, '_self');
    dispatch(stopLoading());
  });
};

export const getAmazonCountryUrls = (appId) => (dispatch) => {
  const params = {
    app_id: appId
  };
  dispatch(getRequest(GET_URL_LIST, params)).then((res) => {
    dispatch(setAmazonCountryUrls(res?.data?.url_list));
  });
};
