import { GET_USER_PROFILE, SUBMIT_COMPANY_DETAILS } from '../backendUrl/apiUrl';

const submitCompanyDetails = async ({ axiosInstance, userData, preAuth }) => {
  const response = await axiosInstance.post(SUBMIT_COMPANY_DETAILS, {
    userData,
    preAuth
  });
  return response;
};
const getUserProfile = async ({ axiosInstance }) => {
  const resp = await axiosInstance.get(GET_USER_PROFILE);
  return resp.data;
};

export { getUserProfile, submitCompanyDetails };
