import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
// components
import { MIconButton } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import { useDispatch, useSelector } from '../../redux/store';
import { CHANGE_PASSWORD } from '../../backendUrl/apiUrl';
import { postRequest } from '../../utils/axios';
import { auth0Config } from '../../config';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { user, logout } = useAuth0();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const handleClose = () => {
    setOpen(false);
  };
  const passwordChangeTriggered = () => {
    dispatch(postRequest(CHANGE_PASSWORD, { email: userInfo.email }, {}, {}, true))
      .then((response) => {
        handleClose();

        enqueueSnackbar(response.message, { variant: 'success' });
      })
      .catch((error) => {
        handleClose();
        enqueueSnackbar('something wen wrong', { variant: 'error' });
      });
  };

  const MENU_OPTIONS = [
    {
      label: 'Change password',
      icon: roundVpnKey,
      onClick: passwordChangeTriggered
    }
  ];
  const handleOpen = () => {
    setOpen(true);
  };
  const handleLogout = async () => {
    try {
      await logout({
        returnTo: window.location.origin,
        clientId: auth0Config.clientId
      });
      if (isMountedRef.current) {
        handleClose();
      }
      // Clear any local storage or state
      localStorage.clear();
      sessionStorage.clear();
      // Clear any application state
      // dispatch(clearUserState());
    } catch (error) {
      console.error('Logout error:', error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <MyAvatar />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} onClick={() => option.onClick()} sx={{ typography: 'body2', py: 1, px: 2.5 }}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
