import { gql } from '@apollo/client';

const GET_COHORTS = gql`
  query {
    customerCohorts {
      id
      name
      description
      cohortType
      membersCount
      netmv
      orderCount
      avgNetmv
    }
  }
`;
const GET_COHORT_SUMMARY = gql`
  query ($cohortId: String) {
    cohortSummary(cohortId: $cohortId) {
      name
      description
      gmv
      netmv
      orderCount
      netaov
      netAsp
      productCount
      productCountPerOrder
      avgNetmv
      avgGmv
      avgOrderCount
      discount
      discountValue
      averageDaysBetweenOrders
      membersCount
    }
  }
`;

const GET_FIRST_CATEGORY_BREAKUP_OF_COHORT = gql`
  query ($cohortId: String) {
    firstCategoryOfCohort(cohortId: $cohortId) {
      customersCount
      category {
        _id
        name
      }
    }
  }
`;
const GET_LAST_CATEGORY_BREAKUP_OF_COHORT = gql`
  query ($cohortId: String) {
    lastCategoryOfCohort(cohortId: $cohortId) {
      customersCount
      category {
        _id
        name
      }
    }
  }
`;

const GET_COHORT_CUSTOMERS = gql`
  query ($cohortId: String!, $page: Int!, $limit: Int!) {
    cohortCustomers(cohortId: $cohortId, page: $page, limit: $limit) {
      customers {
        id
        name
        email
        phone
      }
      totalRows
    }
  }
`;
export {
  GET_COHORTS,
  GET_COHORT_SUMMARY,
  GET_FIRST_CATEGORY_BREAKUP_OF_COHORT,
  GET_LAST_CATEGORY_BREAKUP_OF_COHORT,
  GET_COHORT_CUSTOMERS
};
