import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { useNavigate } from 'react-router-dom'; // material
import { alpha, styled } from '@mui/material/styles';
import { Box, Typography, Stack, AppBar, Toolbar, IconButton, Button } from '@mui/material';

// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
// import NotificationsPopover from './NotificationsPopover';
import { PATH_DASHBOARD } from '../../routes/paths';
import { useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';
import useUserProfile from '../../hooks/useUserProfile';
import DemoUserBanner from './demoUserBanner';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
const BannerStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.action.active,
  minHeight: 40,
  alignItems: 'center'
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

const FreePlanBanner = () => {
  const navigate = useNavigate();
  return (
    <BannerStyle>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} p={1}>
        <Typography variant="subtitle2" color="white">
          You are currently on Free Plan
        </Typography>
        <Button variant="contained" color="warning" onClick={() => navigate('/dashboard/settings/change-plans')}>
          Upgrade
        </Button>
      </Stack>
    </BannerStyle>
  );
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const userProfile = useUserProfile();
  const subscribedPlan = userProfile?.profile?.user?.subscription?.plan;
  const { user, logout } = useAuth0();

  return (
    <Box>
      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
          })
        }}
      >
        {user && user.email === 'demo@niblin.com' && <DemoUserBanner logout={logout} />}
        {/* Banner for Free plan */}
        {user.email !== 'demo@niblin.com' && subscribedPlan && subscribedPlan.identifier === 'FREE' && (
          <FreePlanBanner />
        )}
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>

          {/* <Searchbar /> */}
          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
            {/* <LanguagePopover /> */}
            {/* <NotificationsPopover /> */}
            {/* <ContactsPopover /> */}
            {/* 
          <Box sx={{ mr: 2 }} onClick={() => navigate(PATH_DASHBOARD.general.cart)}>
            <Badge showZero badgeContent={cart?.name ? 1 : 0} color="error" max={99}>
              <Icon icon={shoppingCartFill} width={34} height={34} color="darkgrey" />
            </Badge>
          </Box>
        */}
            <AccountPopover />
          </Stack>
        </ToolbarStyle>
      </RootStyle>
    </Box>
  );
}
