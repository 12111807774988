import { useNavigate, Navigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Alert,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  FormControl,
  FormHelperText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useAxios from '../../hooks/useAxios';
// components
import { PATH_DASHBOARD } from '../../routes/paths';
import { submitCompanyDetails } from '../../actions/users';
// actions
import { getCurrencyOptions } from '../../actions/common';
//

// ----------------------------------------------------------------------

export default function OnboardingForm({ preAuth, userStatusRefetch }) {
  const isMountedRef = useIsMountedRef();
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: (userData) => submitCompanyDetails({ axiosInstance, userData, preAuth })
  });
  const {
    data: currencyOptions,
    isLoading: isCurrencyLoading,
    isError: isCurrencyError
  } = useQuery({
    queryKey: 'currencyOptions',
    queryFn: () => getCurrencyOptions({ axiosInstance })
  });

  // eslint-disable-next-line
  const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/;

  const validationSchemaDef = Yup.object().shape({
    companyName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name of the company is required'),
    userName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name of the user is required'),
    phoneNumber: Yup.string().min(10, 'Too Short!').max(14, 'Too Long!').required('Phone number is required'),
    orderSize: Yup.string().required('Number of orders is required'),
    currencySelection: Yup.string().required('Currency is required'),
    needDemo: Yup.boolean()
  });

  const formikConfig = useFormik({
    initialValues: {
      companyName: '',
      userName: '',
      phoneNumber: '',
      orderSize: '',
      needDemo: false
    },
    validationSchema: validationSchemaDef,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const userData = {
          companyName: values.companyName,
          userName: values.userName,
          phoneNumber: values.phoneNumber,
          orderSize: values.orderSize,
          currencySelection: values.currencySelection,
          needDemo: values.needDemo
        };
        // put button in loading state
        setSubmitting(true);
        // call the mutation
        mutation.mutate(userData, {
          onSuccess: (resp) => {
            setSubmitting(false);
            console.log('preAuth', preAuth);
            if (preAuth) {
              return navigate('/onboarding/finalize-appstore-redirected-con');
            }
            userStatusRefetch();

            navigate(PATH_DASHBOARD.home.dod);
          },
          onError: (error) => {
            console.error('Error: ', error.message);
            setSubmitting(false);
          }
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formikConfig;

  return (
    <FormikProvider value={formikConfig}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            label="Name of the company"
            {...getFieldProps('companyName')}
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />

          <TextField
            fullWidth
            label="Your Name"
            {...getFieldProps('userName')}
            error={Boolean(touched.userName && errors.userName)}
            helperText={touched.userName && errors.userName}
            inputProps={{ style: { textTransform: 'capitalize' } }}
          />
          <TextField
            fullWidth
            label="Contact number"
            placeholder="Phone number"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
          <InputLabel htmlFor="currencySelection" id="currencySelectionLabel">
            Select company's default currency
          </InputLabel>
          <FormHelperText> All your calculations will be done in this currency. So choose carefully </FormHelperText>
          <Select
            labelId="currencySelectionLabel"
            id="currencySelection"
            {...getFieldProps('currencySelection')}
            error={Boolean(touched.currencySelection && errors.currencySelection)}
            helperText={touched.currencySelection && errors.currencySelection}
          >
            {isCurrencyLoading ? (
              <MenuItem value="USD"> USD </MenuItem>
            ) : (
              currencyOptions.map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {currency.code} ({currency.name})
                </MenuItem>
              ))
            )}
          </Select>

          <InputLabel id="orderNumberSelection"> Number of orders per month </InputLabel>
          <Select
            labelId="orderNumberSelection"
            {...getFieldProps('orderSize')}
            error={Boolean(touched.orderSize && errors.orderSize)}
            helperText={touched.orderSize && errors.orderSize}
          >
            <MenuItem value="<100"> {'< 100'} </MenuItem>
            <MenuItem value="100-500"> 100 - 500</MenuItem>
            <MenuItem value="500-1000"> 500 - 1000</MenuItem>
            <MenuItem value=">1000"> {'> 1000'}</MenuItem>
          </Select>
          <InputLabel> Do you need a demo? </InputLabel>
          <Switch name="needDemo" {...getFieldProps('needDemo')} />
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Start using Niblin
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
