import { createSlice } from '@reduxjs/toolkit';

const initialState = null;

const slice = createSlice({
  name: 'connectionAccountMapping',
  initialState,
  reducers: {
    setConnectionAccountMapping: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setConnectionAccountMapping } = slice.actions;
