import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = [];

const slice = createSlice({
  name: 'tallyFileList',
  initialState,
  reducers: {
    setTallyFiles: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setTallyFiles } = slice.actions;
