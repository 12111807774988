import { hideStoreNameModal } from '../../../../../../../redux/slices/woocommerce/popup';
import { CONNECT_TO_WOCOMMERCE } from '../../../../../../../backendUrl/apiUrl';
import { postRequest } from '../../../../../../../utils/axios';
import { getAppConnection } from '../../action';

export const handleWoocommerceConnect =
  (values, { resetForm, setSubmitting }, supportedConnection, appId) =>
  (dispatch, getState) => {
    const { userInfo } = getState();
    const body = {
      user_id: userInfo._id,
      supported_connection_id: supportedConnection._id,
      app_id: appId,
      store_url: values.storeUrl,
      app_client_id: values.clientId,
      app_client_secret: values.clientSecret
    };
    dispatch(postRequest(CONNECT_TO_WOCOMMERCE, body)).then((res) => {
      console.log('res..........', res);
      setSubmitting(false);
      resetForm();
      dispatch(hideStoreNameModal());
      dispatch(getAppConnection(supportedConnection));
    });
  };
