import { connect } from 'react-redux';
import Component from './Component';
import { handleAmazonConnect, getAmazonCountryUrls } from './action';
import { hideAmazonCountryModal } from '../../../../../../../redux/slices/amazon/popup';

const mapStateToProps = ({ amazonPopup: { visible }, amazonCountryUrls }) => ({ visible, amazonCountryUrls });

const mapDispatchToProps = { handleAmazonConnect, hideAmazonCountryModal, getAmazonCountryUrls };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
