import { createSlice } from '@reduxjs/toolkit';

const initialState = { analysisdata: ['', ''] };

const slice = createSlice({
  name: 'analysisdata2',
  initialState,
  reducers: {
    setanalysisdata2: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setanalysisdata2 } = slice.actions;
