import { getRequest, postRequest } from '../../../../../utils/axios';
import { CREATE_ACCOUNT_MAPPING, GET_TRANSACTION_TYPES } from '../../../../../backendUrl/apiUrl';
import { setConnectionAccountMapping } from '../../../../../redux/slices/connectionAccountMapping';
import { PATH_DASHBOARD } from '../../../../../routes/paths';

export const getTallyMappingList = (supportedConnection) => (dispatch, getState) => {
  const { userInfo } = getState();
  const transactionTypesReq = {
    user_id: userInfo._id,
    app_id: supportedConnection.from_app_id
  };

  dispatch(getRequest(GET_TRANSACTION_TYPES, transactionTypesReq))
    .then((res) => {
      dispatch(setConnectionAccountMapping({ transactionTypes: res.data.transactionTypes }));
    })
    .catch(() => {
      dispatch(setConnectionAccountMapping({ transactionTypes: [] }));
    });
};

export const handleSubmit = (mappingDetails, connection, supportedConnection, navigate) => (dispatch, getState) => {
  const { userInfo } = getState();
  if (mappingDetails.length < 0) return;
  const req = {
    connection_id: connection._id,
    account_mapping: mappingDetails,
    user_id: userInfo._id
  };
  return dispatch(postRequest(CREATE_ACCOUNT_MAPPING, req)).then((res) => {
    navigate(PATH_DASHBOARD.general.appConnectionSuccess, {
      state: {
        supportedConnection,
        connection
      }
    });
  });
};
