import { connect } from 'react-redux';
import Component from './Component';
import { handleAppConnect, getAppConnection } from './action';
import { getConnections } from '../../action';
import { handleConfigureNow } from '../../../Connections/action';
import { getUserSubsCription } from '../../../Subscription/action';

const mapStateToProps = ({ supportedConnections, connection, subscriptions }) => ({
  supportedConnections,
  connection,
  handleConfigureNow,
  subscriptions
});

const mapDispatchToProps = {
  handleAppConnect,
  getAppConnection,
  getConnections,
  handleConfigureNow,
  getUserSubsCription
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
