import { hideShopNameModal } from '../../../../../../../redux/slices/shopify/popup';
import { SHOPIFY_CONNECT_URL } from '../../../../../../../backendUrl/apiUrl';
import { getRequest } from '../../../../../../../utils/axios';
import { startLoading, stopLoading } from '../../../../../../../redux/slices/loading';

export const handleShopifyConnect =
  (values, { resetForm, setSubmitting }, supportedConnection, appId) =>
  (dispatch, getState) => {
    dispatch(hideShopNameModal());

    const { userInfo } = getState();
    const params = {
      user_id: userInfo._id,
      supported_connection_id: supportedConnection._id,
      app_id: appId,
      shop_name: values.shopName
    };
    dispatch(startLoading());
    dispatch(getRequest(SHOPIFY_CONNECT_URL, params, {}, true)).then((res) => {
      window.open(res, '_self');
      dispatch(stopLoading());
      setSubmitting(false);
      resetForm();
    });
  };
