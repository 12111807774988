import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    startLoading: () => true,
    stopLoading: () => initialState
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { startLoading, stopLoading } = slice.actions;
