import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false,
  data: {}
};

const slice = createSlice({
  name: 'fetchHistoricalDataModal',
  initialState,
  reducers: {
    showfetchHistoricalDataModal: (state, action) => ({ ...state, visible: true, data: action.payload }),
    hidefetchHistoricalDataModal: (state) => ({ ...state, visible: false, data: {} })
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showfetchHistoricalDataModal, hidefetchHistoricalDataModal } = slice.actions;
