// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// hooks
import useAuth from './hooks/useAuth';
// components
import ApolloWrapper from './graphql/apolloConfig';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import { AxiosProvider } from './contexts/AxiosContext';
import { DateProvider } from './contexts/DateContext';
import ReactQueryWrapper from './contexts/ReactQuery';

// ----------------------------------------------------------------------

export default function App() {
  const { isLoading } = useAuth();
  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ApolloWrapper>
      <ReactQueryWrapper>
        <ThemeConfig>
          <ThemePrimaryColor>
            <ThemeLocalization>
              <RtlLayout>
                <NotistackProvider>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <BaseOptionChartStyle />
                  {/* <Settings /> */}
                  <ScrollToTop />
                  <GoogleAnalytics />
                  <AxiosProvider>
                    <DateProvider>
                      <Router />
                    </DateProvider>
                  </AxiosProvider>
                </NotistackProvider>
              </RtlLayout>
            </ThemeLocalization>
          </ThemePrimaryColor>
        </ThemeConfig>
      </ReactQueryWrapper>
    </ApolloWrapper>
  );
}
//
