import {
  DELETE_CONNECTION,
  GET_GOOGLE_ADS_CUSTOMERS,
  PAUSE_CONNECTION,
  RESUME_CONNECTION,
  SAVE_GOOGLE_ADS_CUSTOMERS
} from '../backendUrl/apiUrl';

export const getGoogleAdsCustomers = async ({ axiosInstance }) => {
  const response = await axiosInstance.get(GET_GOOGLE_ADS_CUSTOMERS);
  return response.data;
};

export const saveGoogleAdsCustomers = async ({ axiosInstance, parentId, childIds }) => {
  const response = await axiosInstance.post(SAVE_GOOGLE_ADS_CUSTOMERS, {
    parentId,
    childIds
  });
  return response;
};

export const deleteConnection = async ({ axiosInstance, appId }) => {
  const response = await axiosInstance.delete(DELETE_CONNECTION.replace(':id', appId));
  return response;
};

export const pauseConnection = async ({ axiosInstance, appId }) => {
  const response = await axiosInstance.delete(PAUSE_CONNECTION.replace(':id', appId));
  return response;
};
export const resumeConnection = async ({ axiosInstance, appId }) => {
  const response = await axiosInstance.put(RESUME_CONNECTION.replace(':id', appId));
  return response;
};
