// material
import { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Grid, Typography, Button, TextField, Box } from '@mui/material';
import useSettings from '../../../../../hooks/useSettings';
// components
import Page from '../../../../../components/Page';
import LoadingScreen from '../../../../../components/LoadingScreen';

// ----------------------------------------------------------------------

export default function ConnectionConfigurationScrren({
  getTallyMappingList,
  connectionAccountMapping,
  loading,
  handleSubmit
}) {
  const { themeStretch } = useSettings();

  const [mappingDetails, setMappingDetails] = useState([]);

  const {
    state: { supportedConnection, connection }
  } = useLocation();

  const navigate = useNavigate();

  const handleChange = (e, transactionTypeObj) => {
    const foundIndex = mappingDetails.findIndex((x) => x.transactionTypeID === transactionTypeObj.id);

    if (foundIndex !== -1) {
      mappingDetails[foundIndex] = {
        transactionTypeID: transactionTypeObj.id,
        accountInfo: { Name: e.target.value }
      };
    } else {
      setMappingDetails([
        ...mappingDetails,
        {
          transactionTypeID: transactionTypeObj.id,
          accountInfo: { Name: e.target.value }
        }
      ]);
    }
  };

  useEffect(() => {
    getTallyMappingList(supportedConnection);
  }, []);

  return (
    <Page title=" Configure App | Niblin">
      {loading ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
          <Grid item>
            <LoadingScreen />
          </Grid>
        </Grid>
      ) : (
        <Container maxWidth={themeStretch ? false : 'xl'} sx={{ mb: 15 }}>
          <div style={{ position: 'absolute', top: 90 }}>
            <Button type="button" size="medium" onClick={() => navigate(-1)} sx={{ mt: 2 }}>
              Back
            </Button>
          </div>

          <Box sx={{ mt: 6 }}>
            <Typography variant="h4"> Create accounts mapping</Typography>
          </Box>

          <Grid container spacing={3} sx={{ mt: 4 }}>
            <Grid item xs={6} md={4}>
              <Typography variant="h5">Transaction Type</Typography>
            </Grid>
            <Grid item xs={6} md={8}>
              <Typography variant="h5">Account Name</Typography>
            </Grid>
            {connectionAccountMapping?.transactionTypes?.map((transactionTypesObj) => (
              <Fragment key={transactionTypesObj.id}>
                <Grid item xs={6} md={4}>
                  <Typography>{` ${transactionTypesObj.name} `}</Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <TextField
                    fullWidth
                    label="Enter Account Name"
                    onChange={(e) => handleChange(e, transactionTypesObj)}
                    // {...getFieldProps('title')}
                    // error={Boolean(touched.title && errors.title)}
                    // helperText={touched.title && errors.title}
                  />
                </Grid>
              </Fragment>
            ))}
          </Grid>
          <br />
          <Grid item xs={12} md={12}>
            <Button
              type="button"
              variant="contained"
              fullWidth
              size="large"
              onClick={() => {
                handleSubmit(mappingDetails, connection, supportedConnection, navigate);
              }}
              sx={{ mr: 1 }}
              disabled={mappingDetails.length !== connectionAccountMapping?.transactionTypes?.length}
            >
              Next
            </Button>
          </Grid>

          <br />
        </Container>
      )}
    </Page>
  );
}
