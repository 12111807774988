import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mapping: [],
  appCronFrequency: ''
};

const slice = createSlice({
  name: 'userAccountMapping',
  initialState,
  reducers: {
    setuserAccountMapping: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setuserAccountMapping } = slice.actions;
