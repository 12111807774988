import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = [];

const slice = createSlice({
  name: 'amazonCountryUrls',
  initialState,
  reducers: {
    setAmazonCountryUrls: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setAmazonCountryUrls } = slice.actions;
