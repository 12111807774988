import { useLocation, useNavigate, Outlet } from 'react-router-dom';
// material
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
// icons
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CreditScoreIcon from '@mui/icons-material/CreditScore';

import { PageTitle } from '../components/headings';

export default function SettingsLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    navigate(`/dashboard/settings/${tab}`);
  };
  return (
    <Container>
      <PageTitle>Settings</PageTitle>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack direction="row" spacing={4}>
          <Button
            onClick={() => handleTabClick('')}
            color={
              location.pathname === '/dashboard/settings' || location.pathname === '/dashboard/settings/'
                ? 'secondary'
                : 'inherit'
            }
            sx={{
              borderBottom:
                location.pathname === '/dashobard/settings' || location.pathname === '/dashboard/settings/'
                  ? '4px solid'
                  : 'none',
              borderColor: 'secondary.main'
            }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <ManageAccountsIcon />
              <Typography variant="subtitle2">User Settings</Typography>
            </Stack>
          </Button>
          <Stack>
            <Button
              onClick={() => handleTabClick('change-plans')}
              color={location.pathname.startsWith('/dashboard/settings/change-plans') ? 'secondary' : 'inherit'}
              sx={{
                borderBottom: location.pathname.startsWith('/dashboard/settings/change-plans') ? '4px solid' : 'none',
                borderColor: 'secondary.main'
              }}
            >
              <Stack sx={{ alignItems: 'center' }}>
                <CreditScoreIcon />
                <Typography variant="subtitle2">Billing</Typography>
              </Stack>
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Divider sx={{ marginTop: 1 }} />
      <Box sx={{ mt: 2 }}>
        <Outlet />
      </Box>
    </Container>
  );
}
