import { useState, useRef } from 'react';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { Button, Box, Divider, Typography, Stack, Toolbar, IconButton, Badge, MenuItem, Icon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';

import Logo from '../../components/Logo';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(2, 2, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 2, 0)
  }
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  width: '100%'
}));
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(AppBar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  },
  backgroundColor: theme.palette.neutral
}));
export default function OnboardingNavbar() {
  const { logout, user } = useAuth0();
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Box sx={{ width: 48, height: 48, position: 'absolute', right: 20, top: 20 }}>
        <IconButton onClick={handleOpen} ref={anchorRef}>
          <MyAvatar />
        </IconButton>
      </Box>
      <Menu open={open} anchorEl={anchorRef.current} onClose={handleClose}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        <Divider />
        <MenuItem onClick={() => logout()}>
          <Typography>Logout</Typography>
        </MenuItem>
      </Menu>
    </RootStyle>
  );
}
