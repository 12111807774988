import { gql } from '@apollo/client';

const GET_SUMMARY_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    summaryDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      totalItemsSold
      orderCount
      netmv
      netaov
      netAsp
      itemCountPerOrder
      gmv
      discount
      discountValue
      dateObj {
        date
      }
    }
  }
`;
const GET_SUMMARY_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    summaryWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      totalItemsSold
      orderCount
      netmv
      netaov
      netAsp
      itemCountPerOrder
      gmv
      discount
      discountValue
      dateObj {
        year
        week
      }
    }
  }
`;

const GET_SUMMARY_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    summaryMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      totalItemsSold
      orderCount
      netmv
      netaov
      netAsp
      itemCountPerOrder
      gmv
      discount
      discountValue
      dateObj {
        year
        month
      }
    }
  }
`;

const GET_DISCOUNT_SUMMARY_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    discountSummaryDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        date
        year
      }
      discountDistribution {
        code
        count
        discountValue
      }
    }
  }
`;
const GET_DISCOUNT_SUMMARY_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    discountSummaryWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        week
      }
      discountDistribution {
        code
        count
        discountValue
      }
    }
  }
`;
const GET_DISCOUNT_SUMMARY_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    discountSummaryMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        month
      }
      discountDistribution {
        code
        count
        discountValue
      }
    }
  }
`;
const GET_OVERALL_SUMMARY = gql`
  query {
    overallSummary {
      month
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
    }
  }
`;
const GET_TODAY_SALES = gql`
  query {
    currentDaySummary {
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
      discount
      dateObj {
        date
        year
      }
    }
  }
`;
const GET_CURRENT_WEEK_SUMMARY = gql`
  query {
    currentWeekSummary {
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
      discount
      dateObj {
        week
        year
      }
    }
  }
`;

const GET_CURRENT_MONTH_SUMMARY = gql`
  query {
    currentMonthSummary {
      month
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
      discount
      dateObj {
        month
        year
      }
    }
  }
`;

const GET_WEEKLY_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    weeklySummary(fromDate: $fromDate, toDate: $toDate) {
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
      dateObj {
        week
        year
      }
    }
  }
`;

const GET_MONTHLY_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    monthlySummary(fromDate: $fromDate, toDate: $toDate) {
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
      dateObj {
        month
        year
      }
    }
  }
`;

const GET_TOP_CITIES_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    topCitiesDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      id
      city
      salesDistribution {
        orderCount
        netmv
      }
    }
  }
`;
const GET_TOP_CITIES_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    topCitiesWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      id
      city
      salesDistribution {
        orderCount
        netmv
      }
    }
  }
`;

const GET_TOP_CITIES_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    topCitiesMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      id
      city
      salesDistribution {
        orderCount
        netmv
      }
    }
  }
`;
export {
  GET_SUMMARY_DOD,
  GET_SUMMARY_WOW,
  GET_SUMMARY_MOM,
  GET_DISCOUNT_SUMMARY_DOD,
  GET_DISCOUNT_SUMMARY_WOW,
  GET_DISCOUNT_SUMMARY_MOM,
  GET_OVERALL_SUMMARY,
  GET_TODAY_SALES,
  GET_CURRENT_WEEK_SUMMARY,
  GET_CURRENT_MONTH_SUMMARY,
  GET_WEEKLY_SUMMARY,
  GET_MONTHLY_SUMMARY,
  GET_TOP_CITIES_DOD,
  GET_TOP_CITIES_WOW,
  GET_TOP_CITIES_MOM
};
