import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isWoocommerceStoreurlVisible: false
};

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showStoreNameModal: (state) => ({ ...state, isWoocommerceStoreurlVisible: true }),
    hideStoreNameModal: (state) => ({ ...state, isWoocommerceStoreurlVisible: false })
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showStoreNameModal, hideStoreNameModal } = slice.actions;
