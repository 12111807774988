// This page is used to redirect to Google Ads Auth page
import { useEffect } from 'react';
import useAxios from '../../../../../hooks/useAxios';
import { GET_GOOGLE_ADS_AUTH_URL } from '../../../../../backendUrl/apiUrl';

export default function RedirectToGoogleAdsAuth() {
  const axiosInstance = useAxios();
  useEffect(async () => {
    await axiosInstance
      .get(GET_GOOGLE_ADS_AUTH_URL)
      .then((resp) => {
        window.open(resp.data?.authorizationUrl, '_self');
      })
      .catch((err) => console.log('error in authorizationUrl', err));
  }, []);
  return (
    <div>
      <p>redirectToGoogleAdsAuth</p>
    </div>
  );
}
