import * as Yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, Button, TextField, DialogActions, DialogContent, DialogTitle, Dialog } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const getInitialValues = () => {
  const account = {
    storeUrl: '',
    clientId: '',
    clientSecret: ''
  };

  return account;
};

const WoocommerceStoreURL = ({
  isWoocommerceStoreurlVisible,
  handleWoocommerceConnect,
  hideStoreNameModal,
  supportedConnection,
  appId
}) => {
  const EventSchema = Yup.object().shape({
    storeUrl: Yup.string().required('store url is required'),
    clientId: Yup.string().required('client Id is required'),
    clientSecret: Yup.string().required('client secret is required')
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: EventSchema,
    onSubmit: async (values, props) => {
      handleWoocommerceConnect(values, props, supportedConnection, appId);
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <Dialog fullWidth open={isWoocommerceStoreurlVisible} maxWidth="sm" scroll="paper" onClose={() => {}}>
      <DialogTitle>Enter Store URL</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="Enter Store URL"
                {...getFieldProps('storeUrl')}
                error={Boolean(touched.storeUrl && errors.storeUrl)}
                helperText={touched.storeUrl && errors.storeUrl}
              />
            </Stack>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="Enter client Id"
                {...getFieldProps('clientId')}
                error={Boolean(touched.clientId && errors.clientId)}
                helperText={touched.clientId && errors.clientId}
              />
            </Stack>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                label="Enter Client Secret"
                {...getFieldProps('clientSecret')}
                error={Boolean(touched.clientSecret && errors.clientSecret)}
                helperText={touched.clientSecret && errors.clientSecret}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="button" variant="outlined" color="inherit" onClick={() => hideStoreNameModal()}>
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting} loadingIndicator="Loading...">
              Connect
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default WoocommerceStoreURL;
