import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  dateRange: {
    fromDate: dayjs(Date.now()).subtract(7, 'day').toISOString(),
    toDate: dayjs(Date.now()).toISOString()
  },
  data: []
};

const slice = createSlice({
  name: 'allOrders',
  initialState,
  reducers: {
    setOrders: (state, action) => {
      state.dateRange.fromDate = action.payload.dateRange.fromDate;
      state.dateRange.toDate = action.payload.dateRange.toDate;
      state.data = action.payload.data;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setOrders } = slice.actions;
