import * as Yup from 'yup';

import { useFormik, Form, FormikProvider } from 'formik';

import { Stack, Button, TextField, DialogActions, DialogContent, DialogTitle, Dialog, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const getInitialValues = () => {
  const account = {
    shopName: localStorage.getItem('shopName') !== 'undefined' ? localStorage.getItem('shopName') : ''
  };

  return account;
};

const ShopifyShopName = ({
  isShopifyShopNameVisible,
  handleShopifyConnect,
  hideShopNameModal,
  supportedConnection,
  appId
}) => {
  const EventSchema = Yup.object().shape({
    shopName: Yup.string().required('shopName is required')
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: EventSchema,
    onSubmit: async (values, props) => {
      handleShopifyConnect(values, props, supportedConnection, appId);
    }
  });

  const { values, errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <Dialog fullWidth open={isShopifyShopNameVisible} maxWidth="sm" scroll="paper" onClose={() => {}}>
      <DialogTitle>Enter shop name</DialogTitle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Stack spacing={3} sx={{ p: 3 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: 'https://',
                  endAdornment: '.myshopify.com'
                }}
                label="Shop Name"
                {...getFieldProps('shopName')}
                error={Boolean(touched.shopName && errors.shopName)}
                helperText={touched.shopName && errors.shopName}
              />
            </Stack>
          </DialogContent>

          <DialogActions>
            <Button type="button" variant="outlined" color="inherit" onClick={() => hideShopNameModal()}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={isSubmitting}
              loadingIndicator="Loading..."
              disabled={!values.shopName}
            >
              Connect
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
      )
    </Dialog>
  );
};

export default ShopifyShopName;
