import axios from 'axios';
import { GET_CURRENCY_OPTIONS, VALIDATE_REDIRECT_HMAC, GET_COMPANY_STATUS } from '../backendUrl/apiUrl';

const getCurrencyOptions = async ({ axiosInstance }) => {
  const response = await axiosInstance.get(GET_CURRENCY_OPTIONS);
  return response.data;
};
const validateHmac = async (data) => {
  const response = await axios.post(VALIDATE_REDIRECT_HMAC, data);
  return response.data;
};
const getCompanyStatus = async ({ axiosInstance }) => {
  const response = await axiosInstance.get(GET_COMPANY_STATUS);
  return response.data;
};

export { getCurrencyOptions, validateHmac, getCompanyStatus };
