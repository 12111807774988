import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Container, Typography, Link, Button, Grid } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from '../../redux/store';
import { getRequest } from '../../utils/axios';
import { CHECK_SHOP_AVAILIBILITY } from '../../backendUrl/apiUrl';

import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
// import { LoginForm } from '../../components/authentication/login';
// import { PATH_AUTH } from '../../routes/paths';
// import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login({ requestedLocation }) {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  const isRedirect = localStorage.getItem('isRedirect') ? localStorage.getItem('isRedirect') === 'true' : false;
  const shop = localStorage.getItem('shop');

  useEffect(() => {
    if (isRedirect) {
      const returnTo = `/dashboard/connections/connect-apps/${localStorage.getItem('appId')}`;
      setRedirectUrl(returnTo);
    } else {
      setRedirectUrl(requestedLocation);
    }
  }, [isRedirect]);

  const renderGreeting = () => {
    if (shop) {
      return (
        <>
          <Typography variant="h4" sx={{ px: 5, mt: 10, mb: 5 }}>
            Please sign in to Niblin to continue
          </Typography>
          <Typography variant="subtitle1" sx={{ px: 5, mt: 10, mb: 5 }} gutterBottom>
            Shop: {shop}
          </Typography>
        </>
      );
    }
    return (
      <Typography variant="h4" gutterBottom>
        Sign in to Niblin
      </Typography>
    );
  };

  return (
    <RootStyle title="Login | Niblin">
      {/* 
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {` Hi, Welcome ${isShop ? 'Back' : ''} ${
              localStorage.getItem('shop') === 'true' ? localStorage.getItem('shopName') : ''
            }`}
          </Typography>
          <img alt="register" src="/static/illustrations/illustration_login.png" />
        </SectionStyle>
      </MHidden>

        */}

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>{renderGreeting()}</Box>

            <Box
              component="img"
              src="https://storage.googleapis.com/niblin-public-images/logo_transparent.png"
              sx={{ width: 80, height: 80, resize: 'contain' }}
            />
          </Stack>

          <br />
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: redirectUrl }
                  })
                }
              >
                Login
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={() =>
                  loginWithRedirect({
                    appState: { returnTo: redirectUrl },
                    authorizationParams: {
                      screen_hint: 'signup'
                    }
                  })
                }
              >
                Signup
              </Button>
            </Grid>
          </Grid>

          {/* <LoginForm /> */}
          {/* <br />
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Don’t have an account?&nbsp;
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              Get started
            </Link>
          </Typography> */}
          <br />
          <Typography variant="body2">
            By clicking sign in, you agree to our
            <Link
              to="/terms-and-conditions"
              key="Terms and Conditions"
              color="primary"
              variant="body2"
              component={RouterLink}
            >
              Terms and Conditions
            </Link>{' '}
            and that you have read our{' '}
            <Link to="/privacy-policy" key="Privacy Policy" color="primary" variant="body2" component={RouterLink}>
              Privacy Policy
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
