import { gql } from '@apollo/client';

const GET_SALES_ANALYTICS = gql`
  query ($from_date: String!, $to_date: String!) {
    salesAnalytics(from_date: $from_date, to_date: $to_date) {
      appLevelSummary {
        app {
          name
        }
        gmv
        orderCount
        aov
      }
      overallSummary {
        gmv
        orderCount
      }
      series {
        date
        salesTotal
        mav
      }
      aggregateDiscountDetails {
        dayWiseDiscount {
          date
          discount
        }
      }
    }
  }
`;

const GET_All_CHANNELS_SALES_SUMMARY = gql`
  query {
    allChannelsSalesSummary {
      id
      channel
      gmv
      netmv
      orderCount
      netaov
      totalItemsSold
      itemCountPerOrder
      netAsp
      discountValue
    }
  }
`;

const GET_ALL_CHANNELS_DISCOUNT_SUMMARY = gql`
  query {
    allChannelsDiscountSummary {
      channel
      discountDistribution {
        code
        count
        discountValue
      }
    }
  }
`;
export { GET_SALES_ANALYTICS, GET_All_CHANNELS_SALES_SUMMARY, GET_ALL_CHANNELS_DISCOUNT_SUMMARY };
