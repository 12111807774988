import { Suspense, lazy, useEffect } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import OnboardingLayout from '../layouts/onboarding';
import SettingsLayout from '../layouts/SettingsLayout';
// guards
import GuestGuard from '../guards/GuestGuard';

import AuthGuard from '../guards/AuthGuard';
import OnboardingGuard from '../guards/OnboardingGuard';
// import  from '../guards/RoleBasedGuard';RoleBasedGuard
// components
import LoadingScreen from '../components/LoadingScreen';
import { useDispatch } from '../redux/store';

import AppConnection from '../pages/dashboard/GeneralApp/components/AppConnection';
import AppConfigureScreen from '../pages/dashboard/GeneralApp/components/AppConfigureScreen';
import TallyAppConfigureScreen from '../pages/dashboard/GeneralApp/components/TallyAppConfigureScreen';
import TallyDownload from '../pages/dashboard/GeneralApp/components/TallyDownload';
import ConfigureCronFrequency from '../pages/dashboard/GeneralApp/components/ConfigureCronFrequency';
import AppConnectionSuccess from '../pages/dashboard/GeneralApp/components/ConnectionSuccess';
import useAuth from '../hooks/useAuth';
import RedirectToEtsy from '../pages/dashboard/Connections/connectApps/etsy/redirectToEtsy';
import ConnectApps from '../pages/dashboard/Connections/connectApps';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 200,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const dispatch = useDispatch();
  const { user } = useAuth();

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },

        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'email-verification', element: <EmailVerification /> },
        { path: 'callback', element: <CallbackPage /> },
        { path: 'company-details', element: <CompanyDetailsPage /> }
      ]
    },
    {
      path: 'onboarding',
      element: (
        <AuthGuard>
          <OnboardingLayout />
        </AuthGuard>
      ),
      children: [
        { path: 'connect-apps/:appId', element: <ConnectAppsOnboardingPage /> },
        { path: 'connections/success/:appInfoId', element: <ConnectionSuccessPageOnboarding /> },
        { path: 'connections/success', element: <ConnectionSuccessPageOnboarding /> },
        { path: 'connections/connect-apps/:appId/redirect-to-etsy', element: <RedirectToEtsyOnboarding /> },
        { path: 'connections/error', element: <ConnectionsErrorPageOnboarding /> },
        { path: 'finalize-appstore-redirected-con', element: <FinaliseAppStoreRedirectConnection /> },
        { path: 'company-details', element: <CompanyDetailsPage /> },

        { path: 'connect-inlets', element: <ConnectInletsPage /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <OnboardingGuard>
            <DashboardLayout />
          </OnboardingGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/home" replace /> },
        {
          path: 'app',
          element: <GeneralApp />
        },
        // Connections
        { path: 'connections', element: <ConnectionsPage /> },
        { path: 'connections/connect-apps/:appId', element: <ConnectAppsPage /> },
        { path: 'connections/connect-apps/success/:appInfoId', element: <ConnectionsSuccessPage /> },
        { path: 'connections/connect-apps/error', element: <ConnectionsErrorPage /> },
        { path: 'connections/connect-apps/:appId/redirect-to-etsy', element: <RedirectToEtsy /> },
        {
          path: 'connections/connect-apps/GOOGLE_ADS/select-customer',
          element: <SelectGoogleCustomerPage />
        },
        {
          path: 'app-connection',
          element: <AppConnection />
        },

        {
          path: 'app-configure',
          element: <AppConfigureScreen />
        },
        {
          path: 'tally-app-configure',
          element: <TallyAppConfigureScreen />
        },
        {
          path: 'tally-download',
          element: <TallyDownload />
        },

        {
          path: 'app-cron-frequency',
          element: <ConfigureCronFrequency />
        },
        {
          path: 'app-connection-success',
          element: <AppConnectionSuccess />
        },
        { path: 'pricing', element: <PricingDashboard /> },
        { path: 'pricing/cart', element: <Cart /> },

        { path: 'pricing/checkout', element: <Checkout /> },
        { path: 'pricing/payment/success', element: <PaymentSuccess /> },
        { path: 'pricing/payment/failed', element: <PaymentFailed /> },
        { path: 'subscription', element: <Subscription /> },
        {
          path: 'user',
          children: [{ path: 'account', element: <UserAccount /> }]
        },
        // home page
        { path: 'home', element: <Home /> },
        { path: 'home/dod', element: <HomeDOD /> },
        { path: 'home/wow', element: <HomeWOW /> },
        { path: 'home/mom', element: <HomeMOM /> },
        // channel
        { path: 'channel-analytics/all', element: <ChannelAnalyticsPage /> },
        { path: 'channel-analytics/:channelIdentifier/dod', element: <ChannelDODSummary /> },
        { path: 'channel-analytics/:channelIdentifier/wow', element: <ChannelWOWSummary /> },
        { path: 'channel-analytics/:channelIdentifier/mom', element: <ChannelMOMSummary /> },

        { path: 'orders', element: <Orders /> },
        { path: 'order/:id', element: <Order /> },
        { path: 'customers', element: <Customers /> },
        { path: 'customer/:id', element: <Customer /> },
        { path: 'customer-segmentation', element: <CustomerSegmentationPage /> },
        { path: 'products', element: <Products /> },
        { path: 'product/:id', element: <Product /> },
        { path: 'sales-analytics', element: <SalesAnalyticsPage /> },
        { path: 'category-analytics', element: <CategoryAnalyticsPage /> },
        { path: 'channel/:id', element: <Channel /> },
        { path: 'customer-analytics', element: <CustomerAnalyticsPage /> },
        { path: 'inventory-analytics', element: <InventoryAnalyticsPage /> },
        { path: 'cohorts/:id', element: <CohortAnalysisPage /> },
        { path: 'customer-retention', element: <RetentionAnalyticsPage /> },
        { path: 'rfm', element: <RFMPage /> },
        {
          path: 'settings',
          element: <SettingsLayout />,
          children: [
            { path: '', element: <SettingsPage />, index: true },
            { path: 'change-plans', element: <ChangePlanPage /> },
            { path: 'change-plans/result', element: <SubscriptionResult /> }
          ]
        }
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: 'validate-app-redirect', element: <ValidateAppRedirect /> },
        { path: 'appstore-install-callback', element: <ReceiveAppstoreInstallCallback /> },
        { path: 'store-exists', element: <StoreExistsPage /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          element: (
            <GuestGuard>
              <LandingPage />
            </GuestGuard>
          )
        },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'faqs', element: <Faqs /> },
        { path: 'terms-and-conditions', element: <TermsAndConditions /> },
        { path: 'privacy-policy', element: <PrivacyPolicy /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const EmailVerification = Loadable(lazy(() => import('../pages/authentication/EmailVerification')));
const CallbackPage = Loadable(lazy(() => import('../pages/authentication/CallbackPage')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Checkout = Loadable(lazy(() => import('../pages/dashboard/Pricing/components/Checkout')));
const Cart = Loadable(lazy(() => import('../pages/dashboard/Pricing/components/Cart')));

const PaymentSuccess = Loadable(lazy(() => import('../pages/dashboard/Pricing/components/Success')));
const PaymentFailed = Loadable(lazy(() => import('../pages/dashboard/Pricing/components/Failed')));

const PricingDashboard = Loadable(lazy(() => import('../pages/dashboard/Pricing')));
const Subscription = Loadable(lazy(() => import('../pages/dashboard/Subscription')));
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
// App store redirects
const ValidateAppRedirect = Loadable(lazy(() => import('../pages/AppStoreRedirects')));
const ReceiveAppstoreInstallCallback = Loadable(lazy(() => import('../pages/AppStoreRedirects/receiveCallback')));
const StoreExistsPage = Loadable(lazy(() => import('../pages/AppStoreRedirects/storeExistsPage')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));

// New Pages
const Customers = Loadable(lazy(() => import('../pages/dashboard/Customers')));
const Home = Loadable(lazy(() => import('../pages/dashboard/home')));
const Orders = Loadable(lazy(() => import('../pages/dashboard/Orders')));
const Order = Loadable(lazy(() => import('../pages/dashboard/Order')));
const Customer = Loadable(lazy(() => import('../pages/dashboard/Customer')));
const Products = Loadable(lazy(() => import('../pages/dashboard/Products')));
const Product = Loadable(lazy(() => import('../pages/dashboard/Product')));
const SalesAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/SalesAnalytics')));
const CategoryAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/CategoryAnalytics')));
const ChannelAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/ChannelAnalytics')));
const Channel = Loadable(lazy(() => import('../pages/dashboard/Channel')));
const CustomerAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/CustomerAnalytics')));
const InventoryAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/InventoryAnalytics')));
// Home Page
const HomeDOD = Loadable(lazy(() => import('../pages/dashboard/home/dod')));
const HomeWOW = Loadable(lazy(() => import('../pages/dashboard/home/wow')));
const HomeMOM = Loadable(lazy(() => import('../pages/dashboard/home/mom')));
const ChannelDODSummary = Loadable(lazy(() => import('../pages/dashboard/ChannelAnalytics/dod')));
const ChannelWOWSummary = Loadable(lazy(() => import('../pages/dashboard/ChannelAnalytics/wow')));
const ChannelMOMSummary = Loadable(lazy(() => import('../pages/dashboard/ChannelAnalytics/mom')));
// Connections
const ConnectionsPage = Loadable(lazy(() => import('../pages/dashboard/Connections')));
const ConnectAppsPage = Loadable(lazy(() => import('../pages/dashboard/Connections/connectApps')));
const ConnectionsSuccessPage = Loadable(lazy(() => import('../pages/dashboard/Connections/connectApps/success')));
const ConnectionsErrorPage = Loadable(lazy(() => import('../pages/dashboard/Connections/connectApps/error')));
const RedirecToEtsy = Loadable(lazy(() => import('../pages/dashboard/Connections/connectApps/etsy/redirectToEtsy')));
const CohortAnalysisPage = Loadable(lazy(() => import('../pages/dashboard/CohortAnalysis')));
const RetentionAnalyticsPage = Loadable(lazy(() => import('../pages/dashboard/Retention')));
const CustomerSegmentationPage = Loadable(lazy(() => import('../pages/dashboard/CustomerSegmentation')));
const SelectGoogleCustomerPage = Loadable(
  lazy(() => import('../pages/dashboard/Connections/connectApps/googleAds/selectGoogleCustomer'))
);
// Onboarding
const ConnectAppsOnboardingPage = Loadable(lazy(() => import('../pages/Onboarding/connectApps')));
const ConnectionSuccessPageOnboarding = Loadable(lazy(() => import('../pages/Onboarding/connectApps/success')));
const RedirectToEtsyOnboarding = Loadable(lazy(() => import('../pages/Onboarding/connectApps/etsy/redirectToEtsy')));
const ConnectionsErrorPageOnboarding = Loadable(lazy(() => import('../pages/Onboarding/connectApps/error')));
const FinaliseAppStoreRedirectConnection = Loadable(
  lazy(() => import('../pages/AppStoreRedirects/finaliseConnection'))
);
const CompanyDetailsPage = Loadable(lazy(() => import('../pages/Onboarding/companyDetails')));
const ConnectInletsPage = Loadable(lazy(() => import('../pages/Onboarding/connectInlets')));

// Customer analytics
const RFMPage = Loadable(lazy(() => import('../pages/dashboard/RFM')));

// Settings
const ChangePlanPage = Loadable(lazy(() => import('../pages/dashboard/Settings/changePlan')));
const SubscriptionResult = Loadable(lazy(() => import('../pages/dashboard/Settings/subscriptionResult')));
const SettingsPage = Loadable(lazy(() => import('../pages/dashboard/Settings')));
