import ShopNameShopify from './shopify/shopName';
import SelectCountry from './amazon/selectCountry';
import RedirectToEtsy from './etsy/redirectToEtsy';
import ShopNameEtsy from './etsy/shopNameEtsy';
import ConfirmAndRedirectToKlaviyo from './klaviyo/confirmAndRedirect';
import RedirectToGoogleAdsAuth from './googleAds/redirectToGoogleAdsAuth';
import SelectGoogleCustomer from './googleAds/selectGoogleCustomer';

const componentMapping = {
  SHOP_NAME: ShopNameShopify,
  SELECT_COUNTRY: SelectCountry,
  REDIRECT_TO_ETSY: RedirectToEtsy,
  GET_ETSY_SHOP_ID: ShopNameEtsy,
  CONFIRM_AND_REDIRECT_TO_KLAVIYO: ConfirmAndRedirectToKlaviyo,
  REDIRECT_TO_GOOGLE_ADS_AUTH: RedirectToGoogleAdsAuth,
  SELECT_GOOGLE_CUSTOMER: SelectGoogleCustomer
};
export default componentMapping;
