// material
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Grid, Autocomplete, TextField, Button, Box, Typography, Stack } from '@mui/material';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Icon } from '@iconify/react';
import useSettings from '../../../../../hooks/useSettings';
// components
import Page from '../../../../../components/Page';
import LoadingScreen from '../../../../../components/LoadingScreen';

// ----------------------------------------------------------------------

export default function ConfigureCronFrequency({
  loading,
  handleSubmit,
  getSupportedFrequency,
  userAccountMapping,
  supportedFrequency
}) {
  const {
    state: { connection, supportedConnection }
  } = useLocation();

  const navigate = useNavigate();
  const [cronFrequency, setCronFrequency] = useState(null);

  const handleChange = (value) => {
    setCronFrequency(value?.name);
  };
  useEffect(() => {
    getSupportedFrequency(supportedConnection).then(() => {
      console.log('userAccountMapping?.appCronFrequency.......................', userAccountMapping?.appCronFrequency);
      if (userAccountMapping?.appCronFrequency) {
        setCronFrequency(userAccountMapping?.appCronFrequency);
      }
    });
  }, []);

  console.log('supportedFrequency.......................', supportedFrequency);
  return (
    <>
      <Page title=" Configure App | Niblin">
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
            <Grid item>
              <LoadingScreen />
            </Grid>
          </Grid>
        ) : (
          <Box maxWidth={610} sx={{ margin: 'auto', padding: 3 }}>
            <Stack direction="row">
              <IconButton onClick={() => navigate(-1)}>
                <Icon icon={arrowIosBackFill} width={30} height={30} />
              </IconButton>
              <Typography variant="h5" sx={{ mt: 0.8 }}>
                Select cron frequency
              </Typography>
            </Stack>

            <Grid item xs={12} md={12} sx={{ padding: 4 }}>
              {userAccountMapping?.appCronFrequency ? (
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  autoHighlight
                  onChange={(event, values) => {
                    handleChange(values);
                  }}
                  disableClearable
                  defaultValue={{
                    name: userAccountMapping?.appCronFrequency
                  }}
                  options={supportedFrequency}
                  getOptionLabel={(option) => `${option.name} `}
                  renderInput={(params) => <TextField {...params} label="cron frequency" variant="standard" />}
                />
              ) : (
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  autoHighlight
                  onChange={(event, values) => {
                    handleChange(values);
                  }}
                  disableClearable
                  options={supportedFrequency}
                  getOptionLabel={(option) => `${option.name} `}
                  renderInput={(params) => <TextField {...params} label="cron frequency" variant="standard" />}
                />
              )}
              <Box sx={{ mt: 4 }}>
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={() => {
                    handleSubmit(connection, supportedConnection, cronFrequency, navigate);
                  }}
                  sx={{ mr: 1 }}
                  disabled={!cronFrequency}
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Box>
        )}
      </Page>
    </>
  );
}
