import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  visible: false
};

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showAmazonCountryModal: (state) => ({ ...state, visible: true }),
    hideAmazonCountryModal: (state) => ({ ...state, visible: false })
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showAmazonCountryModal, hideAmazonCountryModal } = slice.actions;
