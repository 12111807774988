import { PATH_DASHBOARD } from '../../../routes/paths';
import { AppName } from '../../../config';
import { showfetchHistoricalDataModal } from '../../../redux/slices/fetchHistoricalDataModal';

export const handleConfigureNow = (navigate, supportedConnection, connection) => () => {
  const navigationUrl =
    supportedConnection.to_app_name === AppName.TALLY
      ? PATH_DASHBOARD.general.tallyAppConfigure
      : PATH_DASHBOARD.general.appConfigure;
  navigate(navigationUrl, {
    state: {
      supportedConnection,
      connection
    }
  });
};

export const fetchHistoricalDatahandler = (connection) => (dispatch) => {
  console.log('connection..................', connection);
  return dispatch(showfetchHistoricalDataModal(connection));
};
