import { useQuery, gql } from '@apollo/client';

const GET_CATEGORIES = gql`
  query ($id: String) {
    categories(id: $id) {
      id
      name
      children {
        id
        name
      }
      parent {
        id
        name
      }
    }
  }
`;

export { GET_CATEGORIES };
