// ----------------------------------------------------------------------
function path(root, sublink) {
  return `${root}${sublink}`;
}

export const ROOT = '/';
const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_SETTINGS = '/dashboard/settings';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
  shopifyLogin: path(ROOTS_AUTH, '/shopify-login'),
  emailVerification: path(ROOTS_AUTH, '/email-verification'),
  onboarding: {
    companyDetails: path(ROOTS_AUTH, '/company-details')
  }
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  blogs: '/Blogs',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  tandc: '/terms-and-conditions',
  validateAppRedirect: '/validate-app-redirect',
  storeExistsPage: '/store-exists'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    home: path(ROOTS_DASHBOARD, '/home'),
    app: path(ROOTS_DASHBOARD, '/app'),
    pricing: path(ROOTS_DASHBOARD, '/pricing'),
    subscription: path(ROOTS_DASHBOARD, '/subscription'),
    cart: path(ROOTS_DASHBOARD, '/pricing/cart'),
    checkout: path(ROOTS_DASHBOARD, '/pricing/checkout'),
    paymentSuccess: path(ROOTS_DASHBOARD, '/pricing/payment/success'),
    paymentFailed: path(ROOTS_DASHBOARD, '/pricing/payment/failed'),
    connection: path(ROOTS_DASHBOARD, '/app-connection'),
    appConfigure: path(ROOTS_DASHBOARD, '/app-configure'),
    tallyAppConfigure: path(ROOTS_DASHBOARD, '/tally-app-configure'),
    tallyDownload: path(ROOTS_DASHBOARD, '/tally-download'),
    configureCronFrequency: path(ROOTS_DASHBOARD, '/app-cron-frequency'),
    appConnectionSuccess: path(ROOTS_DASHBOARD, '/app-connection-success'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  home: {
    root: path(ROOTS_DASHBOARD, '/home'),
    dod: path(ROOTS_DASHBOARD, '/home/dod'),
    wow: path(ROOTS_DASHBOARD, '/home/wow'),
    mom: path(ROOTS_DASHBOARD, '/home/mom')
  },
  orders: {
    root: path(ROOTS_DASHBOARD, '/orders')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order')
  },
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers')
  },
  customerSegmentation: {
    root: path(ROOTS_DASHBOARD, '/customer-segmentation')
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer')
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products')
  },
  product: {
    root: path(ROOTS_DASHBOARD, '/product')
  },
  salesAnalytics: {
    root: path(ROOTS_DASHBOARD, '/sales-analytics')
  },
  categoryAnalytics: {
    root: path(ROOTS_DASHBOARD, '/category-analytics')
  },
  channelAnalytics: {
    root: path(ROOTS_DASHBOARD, '/channel-analytics/all'),
    dod: path(ROOTS_DASHBOARD, '/channel-analytics/:channelName/dod'),
    wow: path(ROOTS_DASHBOARD, '/channel-analytics/:channelName/wow'),
    mom: path(ROOTS_DASHBOARD, '/channel-analytics/:channelName/mom')
  },
  channel: {
    root: path(ROOTS_DASHBOARD, '/channel')
  },
  customerAnalytics: {
    root: path(ROOTS_DASHBOARD, '/customer-analytics')
  },
  inventoryAnalytics: {
    root: path(ROOTS_DASHBOARD, '/inventory-analytics')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  connections: {
    root: path(ROOTS_DASHBOARD, '/connections'),
    connectApps: path(ROOTS_DASHBOARD, '/connections/connect-apps'),
    success: path(ROOTS_DASHBOARD, '/connections/connect-apps/success/:appInfoId'),
    RedirectToEtsy: path(ROOTS_DASHBOARD, '/connections/connect-apps/:appId/redirect-to-etsy')
  },
  cohort: {
    root: path(ROOTS_DASHBOARD, '/cohorts/all'),
    cohortPage: path(ROOTS_DASHBOARD, '/cohorts/:id')
  },
  retention: {
    root: path(ROOTS_DASHBOARD, '/customer-retention')
  },
  rfm: {
    root: path(ROOTS_DASHBOARD, '/rfm')
  }
};
export const PATH_SETTINGS = {
  root: ROOTS_SETTINGS,
  subscription: {
    changePlan: path(ROOTS_SETTINGS, '/change-plans')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
