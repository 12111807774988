const getRootUrl = (env) => {
  const currentHostname = window.location.hostname;
  if (env === 'DEV') return 'http://localhost:3001';

  let rootUrl;
  if (currentHostname === 'niblin.com') {
    // Request is from the naked domain
    rootUrl = 'https://niblin.com';
  } else if (currentHostname === 'www.niblin.com') {
    // Request is from the "www" subdomain
    rootUrl = 'https://www.niblin.com';
  } else {
    // Handle other cases or set a default root URL
    rootUrl = 'https://www.niblin.com'; // Set a default root URL
  }
  return rootUrl;
};

export const ROOT_URL = getRootUrl(process.env.REACT_APP_ENV);

// const LOCAL_HOST = isAmazon ? 'https://6546-2401-4900-1d65-df7f-4d95-1b50-7502-d859.ngrok.io' : 'http://localhost:3001';
// graphql
export const GRAPHQL_URL = `${ROOT_URL}/api/pr/v1/graphql`;
// common
export const GET_SUPPORTED_CONNECTIONS = `${ROOT_URL}/api/pr/v1/common/getSupportedConnections`;
export const GET_CURRENCY_OPTIONS = `${ROOT_URL}/api/pr/v1/common/get-currency-options`;

// user
export const CREATE_USER = `${ROOT_URL}/api/pr/v1/user/create`;
export const CHANGE_PASSWORD = `${ROOT_URL}/api/pr/v1/user/changepassword`;
export const LOGOUT = `${ROOT_URL}/api/pr/v1/user/logout`;
export const SUBMIT_COMPANY_DETAILS = `${ROOT_URL}/api/pr/v1/user/submit-company-details`;
export const GET_COMPANY_STATUS = `${ROOT_URL}/api/pr/v1/user/company-status`;
export const GET_USER_PROFILE = `${ROOT_URL}/api/pr/v1/user/profile`;

// xero
export const CONNECT_TO_XERO = `${ROOT_URL}/api/pr/v1/xero/connect`;
export const CREATE_XERO_ACCOUNT = `${ROOT_URL}/api/pr/v1/xero/createAccount`;
export const GET_XERO_ACCOUNTS = `${ROOT_URL}/api/pr/v1/xero/getAccounts`;

// shopify
export const SHOPIFY_CONNECT_URL = `${ROOT_URL}/api/pr/v1/shopify/connect`;
export const SHOPIFY_APPSTORE_REDIRECT_CONNECT = `${ROOT_URL}/api/pb/v1/shopify/appstore-redirect-connect`;
export const SHOPIFY_APPSTORE_CONNECT_FINAL = `${ROOT_URL}/api/pr/v1/shopify/finalise-appstore-connect`;

// quickbooks
export const CONNECT_TO_QUICKBOOKS = `${ROOT_URL}/api/pr/v1/quickbooks/connect`;
export const GET_QUICKBOOKS_ACCOUNTS = `${ROOT_URL}/api/pr/v1/quickbooks/getAccounts`;
export const CREATE_QUICKBOOKS_ACCOUNT = `${ROOT_URL}/api/pr/v1/quickbooks/createAccount`;

// amazon
export const GET_AMAZON_AUTH_URL = `${ROOT_URL}/api/pr/v1/amazon/getAuthUrl`;
export const GET_URL_LIST = `${ROOT_URL}/api/pr/v1/amazon/getUrlList`;

// etsy
export const CONNECT_TO_ETSY = `${ROOT_URL}/api/pr/v1/etsy/connect`;
export const FIND_ETSY_SHOPS = `${ROOT_URL}/api/pr/v1/etsy/find-etsy-shops`;
export const SET_ETSY_SHOP_DETAILS = `${ROOT_URL}/api/pr/v1/etsy/set-etsy-shop-details`;

// woocommerce
export const CONNECT_TO_WOCOMMERCE = `${ROOT_URL}/api/pr/v1/woocommerce/connect`;
// Klaviyo
export const GET_KLAVIYO_AUTH_URL = `${ROOT_URL}/api/pr/v1/klaviyo/get-auth-url`;
// Google Ads
export const GET_GOOGLE_ADS_AUTH_URL = `${ROOT_URL}/api/pr/v1/googleAds/connect`;

// tally
export const CONNECT_TO_TALLY = `${ROOT_URL}/api/pr/v1/tally/connect`;
export const GENERATE_TALLY_FILE = `${ROOT_URL}/api/pr/v1/tally/genrateTallyFile`;
export const GET_ALL_TALLY_FILES = `${ROOT_URL}/api/pr/v1/tally/getAllTallyFiles`;
export const GET_TALLY_FILE = `${ROOT_URL}/api/pr/v1/tally/getTallyFile`;

// connection or connections
export const CREATE_ACCOUNT_MAPPING = `${ROOT_URL}/api/pr/v1/connection/createAccountMapping`;
export const UPDATE_CRON_FREQ = `${ROOT_URL}/api/pr/v1/connection/updateCronFreq`;
export const GET_CONNECTION = `${ROOT_URL}/api/pr/v1/connection/getConnection`;
export const GET_CONNECTIONS = `${ROOT_URL}/api/pr/v1/connection/getConnections`;
export const GET_TRANSACTION_TYPES = `${ROOT_URL}/api/pr/v1/connection/getTransactionTypes`;
export const GET_CONNECTION_DETAILS = `${ROOT_URL}/api/pr/v1/connection/getConnectionDetails`;
export const GET_ALL_ORDERS = `${ROOT_URL}/api/pr/v1/connection/getAllOrder`;
export const GET_ORDERS = `${ROOT_URL}/api/pr/v1/connection/getOrder`;
export const GET_USER_ACCOUNT_MAPPING = `${ROOT_URL}/api/pr/v1/connection/getUserAccountMapping`;
export const GET_SUPPORTED_FREQUENCY = `${ROOT_URL}/api/pr/v1/connection/getSupportedFrequency`;
export const PUSH_HISTORICAL_DATA = `${ROOT_URL}/api/pr/v1/connection/pushHistoricalData`;
export const INSERT_FREQUENCY = `${ROOT_URL}/api/pr/v1/common/insert-frequency`;
export const DELETE_CONNECTION = `${ROOT_URL}/api/pr/v1/connection/:id`;
export const PAUSE_CONNECTION = `${ROOT_URL}/api/pr/v1/connection/:id/pause`;
export const RESUME_CONNECTION = `${ROOT_URL}/api/pr/v1/connection/:id/resume`;
// google ads
export const GET_GOOGLE_ADS_CUSTOMERS = `${ROOT_URL}/api/pr/v1/googleAds/get-customers`;
export const SAVE_GOOGLE_ADS_CUSTOMERS = `${ROOT_URL}/api/pr/v1/googleAds/save-customers`;

// payment
export const CREATE_CART = `${ROOT_URL}/api/pr/v1/payment/createCart`;
export const CREATE_CUSTOMER_AND_SUBSCRIPTION = `${ROOT_URL}/api/pr/v1/payment/createCustomerAndSubscription`;
export const CREATE_CHECKOUT_SESSION = `${ROOT_URL}/api/pr/v1/payment/create-checkout-session`;
export const GET_USER_SUBSCRIPTIONS = `${ROOT_URL}/api/pr/v1/payment/getUserSubscriptions`;
export const GET_USER_SUBSCRIPTION = `${ROOT_URL}/api/pr/v1/payment/getUserSubscription`;
export const GET_CART = `${ROOT_URL}/api/pr/v1/payment/getCart`;
export const CANCEL_SUBSCRIPTION = `${ROOT_URL}/api/pr/v1/payment/cancelSubscription`;

// auth
export const RESEND_OTP = `${ROOT_URL}/api/pr/v1/auth/resendOTP`;
export const RESET_PASSWORD = `${ROOT_URL}/api/pr/v1/auth/resetPassword`;
export const CONFIRM_RESET_PASSWORD = `${ROOT_URL}/api/pr/v1/auth/confirmResetPassword`;
export const GET_USER = `${ROOT_URL}/api/pr/v1/auth/getUser`;
export const ONBOARDING = `${ROOT_URL}/pr/v1/auth/onboarding`;

// public routes
export const CHECK_SHOP_AVAILIBILITY = `${ROOT_URL}/api/pb/v1/shopify/checkShopAvailibility`;
export const CONNECT_TO_SHOPIFY_PRE_LOGIN = `${ROOT_URL}/api/pb/v1/shopify/connectPreLogin`;
export const CONTACT_FORM = `${ROOT_URL}/api/pb/v1/common/contact-form`;
export const GET_PLANS = `${ROOT_URL}/api/pb/v1/payment/getPlans`;
export const SIGNIN = `${ROOT_URL}/api/pb/v1/auth/signin`;
export const SIGNUP = `${ROOT_URL}/api/pb/v1/auth/signup`;
export const VERIFY = `${ROOT_URL}/api/pb/v1/auth/verify`;
export const VALIDATE_REDIRECT_HMAC = `${ROOT_URL}/api/pb/v1/common/validate-redirect-hmac`;

// Transfers
export const CREATE_CUSTOMER_COHORT_DOWNLOAD_REQUEST = `${ROOT_URL}/api/pr/v1/download/customer-cohort`;
export const GET_TRANSFER_STATUS = `${ROOT_URL}/api/pr/v1/transfers/get-status`;
export const GET_OUTLETS = `${ROOT_URL}/api/pr/v1/transfers/get-outlets`;
export const CREATE_THIRD_PARTY_TRANSFER_REQUEST = `${ROOT_URL}/api/pr/v1/third-party-transfers/create-transfer-request`;
export const GET_INLETS = `${ROOT_URL}/api/pr/v1/transfers/get-inlets`;
// data fetch
export const HANDLE_FIRST_TIME_CONNECTION = `${ROOT_URL}/api/pr/v1/data-fetch/handle-first-time-connection`;
export const GET_FIRST_FETCH_STATUS = `${ROOT_URL}/api/pr/v1/data-fetch/first-fetch-status`;
export const GET_FIRST_INLET = `${ROOT_URL}/api/pr/v1/data-fetch/get-first-inlet`;
// customers
export const SAVE_COHORT = `${ROOT_URL}/api/pr/v1/cohorts/save-cohort`;

// subscription
export const FETCH_PLANS = `${ROOT_URL}/api/pb/v1/subscription/fetch-plans`;
export const SUBSCRIBE_TO_PLAN = `${ROOT_URL}/api/pr/v1/subscriptions`;
