import { postRequest, getRequest } from '../../../../../utils/axios';
import { UPDATE_CRON_FREQ, GET_SUPPORTED_FREQUENCY } from '../../../../../backendUrl/apiUrl';
import { PATH_DASHBOARD } from '../../../../../routes/paths';
import { setSupportedFrequency } from '../../../../../redux/slices/supportedFrequency';

export const handleSubmit = (connection, supportedConnection, cronFrequency, navigate) => (dispatch, getState) => {
  const { userInfo } = getState();
  const req = {
    connection_id: connection._id,
    cron_frequency: cronFrequency,
    user_id: userInfo._id
  };
  return dispatch(postRequest(UPDATE_CRON_FREQ, req))
    .then((res) => {
      navigate(PATH_DASHBOARD.general.appConnectionSuccess, {
        state: { supportedConnection, connection }
      });
    })
    .catch((error) => {
      console.error('SET_CRON error', error);
    });
};

export const getSupportedFrequency = (supportedConnection) => (dispatch) => {
  const params = {
    supported_connection_id: supportedConnection?._id
  };
  return dispatch(getRequest(GET_SUPPORTED_FREQUENCY, params)).then((res) => {
    dispatch(setSupportedFrequency(res?.data?.supportedFrequency));
  });
};
