import { TextField, Grid, Typography, Divider } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import { LoadingButton, DesktopDatePicker } from '@mui/lab';
import { useTable } from 'react-table';

import './table.css';

function Table({ columns, data }) {
  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const TallyDownloadScreen = ({ loading, handleDownload, getAllTallyFiles, tallyFiles, getTallyFile }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [loadingFile, setLoadingFile] = useState(false);

  useEffect(() => {
    getAllTallyFiles();
  }, []);

  const columns = useMemo(
    () => [
      {
        // first group - TV Show
        Header: 'Recent Files',
        // First group columns
        columns: [
          {
            Header: 'To Date',
            accessor: 'min_date'
          },
          {
            Header: 'From Date',
            accessor: 'max_date'
          },
          {
            Header: 'Download File',
            accessor: '_id',
            Cell: ({ cell }) => (
              <LoadingButton
                type="submit"
                variant="outlined"
                size="small"
                loading={loadingFile}
                onClick={() => getTallyFile(cell.row.values, setLoadingFile)}
              >
                Download
              </LoadingButton>
            )
          }
        ]
      }
    ],
    []
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DesktopDatePicker
            label="Form Date"
            value={fromDate}
            minDate={new Date('2014-01-01')}
            maxDate={new Date().setDate(new Date().getDate())}
            onChange={(newValue) => {
              setFromDate(newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DesktopDatePicker
            label="To Date"
            value={toDate}
            minDate={new Date('2014-01-01')}
            maxDate={new Date().setDate(new Date().getDate())}
            onChange={(newValue) => {
              setToDate(newValue);
            }}
            renderInput={(params) => <TextField fullWidth {...params} margin="normal" />}
          />
        </Grid>
        <Grid item xs={12} md={3} sx={{ mt: '20px' }}>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            size="large"
            loading={loading}
            onClick={() => handleDownload(fromDate, toDate)}
          >
            Download
          </LoadingButton>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 3 }}>
        <Divider />
        <Table columns={columns} data={tallyFiles} />
      </Grid>
    </>
  );
};

export default TallyDownloadScreen;
