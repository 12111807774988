import { gql } from '@apollo/client';

const GET_ORDER_TO_DELIVERY_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliverySummaryDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        date
      }
      avgO2S
      avgO2D
      avgS2D
    }
  }
`;
const GET_ORDER_TO_DELIVERY_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliverySummaryWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        week
      }
      avgO2S
      avgO2D
      avgS2D
    }
  }
`;
const GET_ORDER_TO_DELIVERY_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliverySummaryMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        month
      }
      avgO2S
      avgO2D
      avgS2D
    }
  }
`;
const GET_ORDER_TO_DELIVERY_PERCENTILE_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliveryPercentileDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        date
      }
      lt48
      bet48and72
      bet72and96
      bet96and120
      bet120and144
      gt144
    }
  }
`;

const GET_ORDER_TO_DELIVERY_PERCENTILE_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliveryPercentileWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        week
      }
      lt48
      bet48and72
      bet72and96
      bet96and120
      bet120and144
      gt144
    }
  }
`;

const GET_ORDER_TO_DELIVERY_PERCENTILE_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToDeliveryPercentileMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        month
      }
      lt48
      bet48and72
      bet72and96
      bet96and120
      bet120and144
      gt144
    }
  }
`;

const GET_ORDER_TO_SHIP_PERCENTILE_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToShipPercentileDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        date
      }
      lt24
      bet24and48
      bet48and72
      gt72
    }
  }
`;

const GET_ORDER_TO_SHIP_PERCENTILE_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToShipPercentileWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        week
      }
      lt24
      bet24and48
      bet48and72
      gt72
    }
  }
`;

const GET_ORDER_TO_SHIP_PERCENTILE_MOM = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orderToShipPercentileMOM(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        year
        month
      }
      lt24
      bet24and48
      bet48and72
      gt72
    }
  }
`;
const GET_ALL_CHANNELS_O2D_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    allChannelsO2DSummary(fromDate: $fromDate, toDate: $toDate) {
      channel {
        id
        name
        logo
      }
      avgO2S
      avgO2D
      avgS2D
    }
  }
`;

const GET_ALL_CHANNELS_O2D_PERCENTILE = gql`
  query ($fromDate: String, $toDate: String) {
    allChannelsO2DPercentile(fromDate: $fromDate, toDate: $toDate) {
      channel {
        id
        name
        logo
      }
      lt48
      bet48and72
      bet72and96
      bet96and120
      bet120and144
      gt144
    }
  }
`;
const GET_ALL_CHANNELS_O2S_PERCENTILE = gql`
  query ($fromDate: String, $toDate: String) {
    allChannelsO2SPercentile(fromDate: $fromDate, toDate: $toDate) {
      channel {
        id
        name
        logo
      }
      lt24
      bet24and48
      bet48and72
      gt72
    }
  }
`;
export {
  GET_ORDER_TO_DELIVERY_DOD,
  GET_ORDER_TO_DELIVERY_WOW,
  GET_ORDER_TO_DELIVERY_MOM,
  GET_ORDER_TO_DELIVERY_PERCENTILE_DOD,
  GET_ORDER_TO_SHIP_PERCENTILE_DOD,
  GET_ORDER_TO_SHIP_PERCENTILE_WOW,
  GET_ORDER_TO_SHIP_PERCENTILE_MOM,
  GET_ALL_CHANNELS_O2D_SUMMARY,
  GET_ALL_CHANNELS_O2D_PERCENTILE,
  GET_ALL_CHANNELS_O2S_PERCENTILE,
  GET_ORDER_TO_DELIVERY_PERCENTILE_WOW,
  GET_ORDER_TO_DELIVERY_PERCENTILE_MOM
};
