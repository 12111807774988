import { connect } from 'react-redux';
import Component from './Component';
import { getConnectionDetails } from './action';

const mapStateToProps = null;

const mapDispatchToProps = {
  getConnectionDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
