import { createSlice } from '@reduxjs/toolkit';

const initialState = '';

const slice = createSlice({
  name: 'EmailVerificationError',
  initialState,
  reducers: {
    setEmailVerificationError: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setEmailVerificationError } = slice.actions;
