import { connect } from 'react-redux';
import Component from './Component';
import { handleCreateAccount } from './action';
import { hideCreateAccountModal } from '../../../../../../../redux/slices/createAccount';

const mapStateToProps = ({ createAccount, loading }) => ({ createAccount, loading });

const mapDispatchToProps = { handleCreateAccount, hideCreateAccountModal };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
