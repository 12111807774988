import {
  Stack,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Autocomplete
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';

const AmazonCountryListPopup = ({
  visible,
  handleAmazonConnect,
  hideAmazonCountryModal,
  supportedConnection,
  amazonCountryUrls,
  appId,
  getAmazonCountryUrls
}) => {
  const [countryObj, setCountryObj] = useState('');

  useEffect(() => {
    getAmazonCountryUrls(appId);
  }, []);

  return (
    <Dialog fullWidth open={visible} maxWidth="sm" scroll="paper" onClose={() => {}}>
      <DialogTitle>Select Country</DialogTitle>
      <DialogContent>
        <Stack spacing={3} sx={{ p: 3 }}>
          <Autocomplete
            id="free-solo-demo"
            freeSolo
            autoHighlight
            onChange={(event, value) => {
              setCountryObj(value);
            }}
            disableClearable
            options={amazonCountryUrls}
            getOptionLabel={(option) => `${option?.country_name} `}
            renderInput={(params) => <TextField {...params} label="Select Country" />}
          />
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button type="button" variant="outlined" color="inherit" onClick={() => hideAmazonCountryModal()}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loadingIndicator="Loading..."
          onClick={() => handleAmazonConnect(supportedConnection, appId, countryObj)}
          disabled={!countryObj}
        >
          Connect
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AmazonCountryListPopup;
