import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// material UI
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  Alert,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Divider,
  Link,
  Pagination
} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import useAxios from '../../../../../hooks/useAxios';
import { FIND_ETSY_SHOPS, SET_ETSY_SHOP_DETAILS } from '../../../../../backendUrl/apiUrl';
import { SectionHeading, SubSectionHeading, CardTitle } from '../../../../../components/headings';

export default function ShopNamEtsy() {
  const isMountedRef = useIsMountedRef();
  const axiosInstance = useAxios();
  const { appId } = useParams();
  const navigate = useNavigate();
  const [shopList, setShopList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [shopName, setShopName] = useState('');

  const validationSchemaDef = Yup.object().shape({
    shopName: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Name of the shop is required')
  });

  const formikConfig = useFormik({
    initialValues: {
      shopName: ''
    },
    validationSchema: validationSchemaDef,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        setShopName(values.shopName);
        setPage(1);
        await handleShopFind(values.shopName, 1);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({
            afterSubmit: error.message
          });
          setSubmitting(false);
        }
      }
    }
  });
  const handleShopFind = async (shop_name, page) => {
    setLoading(true);
    await axiosInstance
      .get(FIND_ETSY_SHOPS, {
        params: { shop_name, page }
      })
      .then((resp) => {
        console.log('resp', resp.data);
        if (resp.data) {
          setShopList(resp.data.results);
          setLoading(false);
          setTotalPages(Math.ceil(resp.data.count / 25));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log('error in authorizationUrl', err);
      });
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, submitCount } = formikConfig;

  const handleSelectShop = async (shop_obj) => {
    await axiosInstance
      .post(SET_ETSY_SHOP_DETAILS, { params: shop_obj })
      .then((resp) => {
        console.log('shop details saved successfully');
        navigate(`/dashboard/connections/connect-apps/${appId}/redirect-to-etsy`);
      })
      .catch((err) => console.log(err));
  };

  const renderShopList = () =>
    shopList.map((shop) => (
      <Box key={shop.shop_id}>
        <ListItem
          alignItems="flex-start"
          secondaryAction={
            <Button
              variant="outlined"
              onClick={() =>
                handleSelectShop({
                  shop_id: shop.shop_id,
                  shop_name: shop.shop_name,
                  title: shop.title,
                  image_url: shop.image_url_760x100,
                  url: shop.url
                })
              }
            >
              Select
            </Button>
          }
          sx={{ pt: 3, pb: 3, pl: 7, pr: 7 }}
        >
          <ListItemAvatar>
            <Avatar src={shop.image_url_760x100} sx={{ width: 72, height: 72 }} />
          </ListItemAvatar>
          <ListItemText
            primary={<Typography variant="h4"> {shop?.shop_name}</Typography>}
            secondary={
              <>
                {shop.title ? (
                  <Typography
                    sx={{ display: 'inline', fontSize: 18 }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {shop.title}
                    <br />
                  </Typography>
                ) : null}
                <Link
                  href={shop.url}
                  target="
                  _blank"
                  sx={{ fontSize: 18 }}
                >
                  {shop.url}
                </Link>
              </>
            }
          />
        </ListItem>
        <Divider variant="insert" component="li" />
      </Box>
    ));
  const handlePageChange = (page) => {
    setPage(page);
    handleShopFind(shopName, page);
  };
  const NoShopFound = () => (
    <Box sx={{ mt: 2 }}>
      <Alert severity="warning">No Shops found</Alert>
    </Box>
  );
  const ShopList = () =>
    shopList.length === 0 ? (
      <NoShopFound />
    ) : (
      <Paper elevation={0}>
        <List sx={{ mt: 2 }}>{renderShopList()}</List>
        <Box sx={{ mt: 1, p: 2 }}>
          <Pagination count={totalPages} page={page} onChange={(event, page) => handlePageChange(page)} />
        </Box>
      </Paper>
    );
  const Loading = () => (
    <Box sx={{ width: 'inherit', pt: 4 }} alignItems="center" justifyContent="center">
      <LinearProgress />
    </Box>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Card>
        <CardHeader
          title={<CardTitle>Enter your shop name</CardTitle>}
          subheader={
            <Typography variant="body2" color="text.secondary">
              You can search for your shop using the name of your shop. After searching for the name. Please select your
              shop from the list.
            </Typography>
          }
        />
        <CardContent>
          <FormikProvider value={formikConfig}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack direction="column" spacing={3} alignItems="center">
                {errors.afterSubmit && <Alert severity="error"> {errors.afterSubmit} </Alert>}
                <TextField
                  fullWidth
                  label="Name of the Shop"
                  {...getFieldProps('shopName')}
                  error={Boolean(touched.shopName && errors.shopName)}
                  helperText={touched.shopName && errors.shopName}
                />
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  sx={{ width: 164 }}
                >
                  Submit
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
      {submitCount > 0 && !loading ? <ShopList /> : null}
      {loading && <Loading />}
    </Box>
  );
}
