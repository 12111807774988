import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
// import { AuthContext } from '../contexts/JWTContext/Component';
// import { AuthContext } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';
import { AuthContext } from '../contexts/Auth0Context/Component';

// ----------------------------------------------------------------------

// const useAuth = () => useContext(AuthContext);
const useAuth = () => useAuth0();

export default useAuth;
