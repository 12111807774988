// import { useQuery } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';

// components
import { Navigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { GET_USER_PROFILE } from '../graphql/Queries';
import CompanyDetailsPage from '../pages/Onboarding/companyDetails';
// contexts
import UserProfileContext from '../contexts/userProfileContext';
import { getUserProfile } from '../actions/users';
// hooks
import useAxios from '../hooks/useAxios';

export default function OnboardingGuard({ children }) {
  const axiosInstance = useAxios();

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['userProfile'],
    queryFn: async () => {
      const resp = await getUserProfile({ axiosInstance });
      return resp;
    }
  });

  if (isLoading) return <LoadingScreen />;
  if (isError) return <LoadingScreen />;
  if (data.user && data.user.onboarding_status === 'SIGNUP') return <CompanyDetailsPage userStatusRefetch={refetch} />;
  if (data.user && data.company.onboarding_status === 'CREATED') return <Navigate to="/onboarding/connect-inlets" />;
  if (data.user && data.company.onboarding_status === 'FETCHING_HISTORICAL_DATA')
    return <Navigate to="/onboarding/connections/success" />;
  // return <ConnectionSuccessPageOnboarding />;

  return (
    <UserProfileContext.Provider value={{ profile: data, refetchProfile: refetch }}>
      {children}
    </UserProfileContext.Provider>
  );
  // return <>{children}</>;
}
