import { useState } from 'react';
import { useParams } from 'react-router-dom';
// material UI
import { Card, CardHeader, CardContent, Stack, Alert, Box, TextField, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import useAxios from '../../../../../hooks/useAxios';
import { SHOPIFY_CONNECT_URL } from '../../../../../backendUrl/apiUrl';
import { CardTitle } from '../../../../../components/headings';

export default function ShopName() {
  const isMountedRef = useIsMountedRef();
  const axiosInstance = useAxios();
  const { appId } = useParams();

  const isRedirect = localStorage.getItem('isRedirect') ? localStorage.getItem('isRedirect') === 'true' : false;

  let shopName = null;
  if (localStorage.getItem('shop')) {
    [shopName] = localStorage.getItem('shop').split('.');
  }

  const validationSchemaDef = Yup.object().shape({
    shopName: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('Name of the shop is required')
  });

  const formikConfig = useFormik({
    initialValues: {
      shopName: isRedirect ? shopName : ''
    },
    validationSchema: validationSchemaDef,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await handleConnect(values.shopName);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({
            afterSubmit: error.message
          });
          setSubmitting(false);
        }
      }
    }
  });
  const handleConnect = async (shopName) => {
    await axiosInstance
      .get(SHOPIFY_CONNECT_URL, { params: { shop_name: shopName, app_id: appId } })
      .then((resp) => {
        if (resp.data?.authorizationUrl) {
          window.location.href = resp.data?.authorizationUrl;
        }
        // window.open(resp.data?.authorizationUrl, '_blank');
      })
      .catch((err) => console.log('error in authorizationUrl', err));
  };
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formikConfig;
  return (
    <Card>
      <CardHeader
        title={<CardTitle>{isRedirect ? 'We are going to connect to your shop' : 'Enter your shop Name'}</CardTitle>}
        subheader={
          isRedirect ? null : (
            <Typography variant="body2" color="text.secondary">
              Your shop name is located the in URL for your Shopify account--after the https: // and before . myshopify.
              Do not include 'myshopify.com' in the name, only the phrase right before it.For example, if your URL is
              https: //<mark>universal-apples</mark>
              .myshopify.com / admin / settings / checkout, then the store name is universal - apples.
            </Typography>
          )
        }
      />
      <CardContent>
        <FormikProvider value={formikConfig}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack direction="column" spacing={3} alignItems="center">
              {errors.afterSubmit && <Alert severity="error"> {errors.afterSubmit} </Alert>}
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: 'https://',
                  endAdornment: '.myshopify.com',
                  readOnly: isRedirect
                }}
                label="Name of the Shop"
                {...getFieldProps('shopName')}
                error={Boolean(touched.shopName && errors.shopName)}
                helperText={touched.shopName && errors.shopName}
              />
              <LoadingButton size="large" type="submit" variant="contained" loading={isSubmitting} sx={{ width: 164 }}>
                Connect
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
