import { gql } from '@apollo/client';

const GET_CUSTOMERS = gql`
  query ($page: Int!, $limit: Int!) {
    customers(page: $page, limit: $limit) {
      customers {
        id
        name
        email
        phone
        last_order_date
      }
      totalRows
    }
  }
`;
const GET_CUSTOMER = gql`
  query ($id: String!) {
    customer(id: $id) {
      id
      name
      email
      phone
    }
  }
`;
const GET_CUSTOMER_ANALYTICS = gql`
  query ($id: String!) {
    customerAnalytics(id: $id) {
      orders {
        id
        order_id
        order_created_at
        sales_total
        app {
          id
          name
        }
      }
      salesTotal
      aov
      orderCount
      firstOrderDate {
        firstOrderDate
        daysSinceFirstOrder
      }
      lowestCategoryDist {
        label
        salesTotal
        orderCount
      }
      channelDist {
        label
        salesTotal
        orderCount
      }
    }
  }
`;
const GET_FILTERED_CUSTOMERS = gql`
  query ($filters: String) {
    filteredCustomers(filters: $filters) {
      id
      name
      email
      phone
    }
  }
`;

const GET_PRE_DEFINED_FILTERS = gql`
  query {
    preDefinedFilters {
      id
      name
      description
    }
  }
`;
export { GET_CUSTOMERS, GET_CUSTOMER, GET_CUSTOMER_ANALYTICS, GET_FILTERED_CUSTOMERS, GET_PRE_DEFINED_FILTERS };
