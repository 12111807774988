import { connect } from 'react-redux';
import Component from './Component';
import { handleShopifyConnect } from './action';
import { hideShopNameModal } from '../../../../../../../redux/slices/shopify/popup';

const mapStateToProps = ({ shopifyPopup: { isShopifyShopNameVisible }, loading }) => ({
  isShopifyShopNameVisible,
  loading
});

const mapDispatchToProps = { handleShopifyConnect, hideShopNameModal };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
