import { gql, useQuery } from '@apollo/client';

const GET_USER_PROFILE = gql`
  query {
    user {
      id
      name
      nickname
      email
      onboarding_completed
      company {
        name
        onboarding_status
        acquisitionSource
        currency
      }
      subscription {
        plan {
          name
          identifier
        }
        startDate
        endDate
      }
      onboarding_status
    }
  }
`;

export { GET_USER_PROFILE };
