import * as React from 'react';
import { Box, Button, Typography, Stack, Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const DemoUserBanner = ({ logout }) => (
  <Alert
    severity="warning"
    elevation={6}
    action={
      <Button color="inherit" size="small" variant="outlined" onClick={logout}>
        Logout and register
      </Button>
    }
  >
    <AlertTitle>This is a demo account </AlertTitle>
    If you want to start using niblin, create an account
  </Alert>
);

export default DemoUserBanner;
