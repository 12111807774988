import { connect } from 'react-redux';
import Component from './Component';
import { getTallyMappingList, handleSubmit } from './action';

const mapStateToProps = ({ connectionAccountMapping, loading }) => ({
  connectionAccountMapping,
  loading
});

const mapDispatchToProps = { getTallyMappingList, handleSubmit };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
