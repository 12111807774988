import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, gql, HttpLink } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import useAuth from '../hooks/useAuth';

const { GRAPHQL_URL } = require('../backendUrl/apiUrl');

export default function ApolloWrapper({ children }) {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims, user } = useAuth0();

  const [token, setToken] = useState('');
  const [userData, setUserDataHeader] = useState(null);

  useEffect(async () => {
    let token = null;
    if (isAuthenticated) {
      token = await getAccessTokenSilently();
    }
    setToken(token);
  }, [token]);

  const httpLink = new HttpLink({
    uri: GRAPHQL_URL,
    credentials: 'include'
  });
  // set userData
  useEffect(() => {
    if (user) {
      setUserDataHeader(JSON.stringify(user));
    }
  }, [user]);
  const authLink = setContext(async (_, { headers }) => {
    console.log('');
    const updatedHeaders = {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    };
    if (userData) {
      updatedHeaders.userData = userData;
    }
    return {
      headers: updatedHeaders
    };
  });
  const link = authLink.concat(httpLink);

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            cohortCustomers: {
              keyArgs: false,
              merge(existing = { customers: [], totalRows: 1 }, incoming) {
                const newCustomers = [...existing.customers, ...incoming.customers];
                return { customers: newCustomers, totalRows: incoming.totalRows };
              },
              read(existing, { args: { page, limit } }) {
                return (
                  existing && {
                    customers: existing.customers.slice((page - 1) * limit, page * limit),
                    toatlRows: existing.totalRows
                  }
                );
              }
            },
            products: {
              keyArgs: false,
              merge(existing = { products: [], totalRows: 1 }, incoming) {
                const newProducts = [...existing.products, ...incoming.products];
                return { products: newProducts, totalRows: incoming.totalRows };
              },
              read(existing, { args: { page, limit } }) {
                return (
                  existing && {
                    products: existing.products.slice((page - 1) * limit, page * limit),
                    toatlRows: existing.totalRows
                  }
                );
              }
            },
            customers: {
              keyArgs: false,
              merge(existing = { customers: [], totalRows: 1 }, incoming) {
                const newCustomers = [...existing.customers, ...incoming.customers];
                return { customers: newCustomers, totalRows: incoming.totalRows };
              },
              read(existing, { args: { page, limit } }) {
                return (
                  existing && {
                    customers: existing.customers.slice((page - 1) * limit, page * limit),
                    toatlRows: existing.totalRows
                  }
                );
              }
            }
          }
        }
      }
    }),
    link
  });
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
