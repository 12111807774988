import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = [];

const slice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    setConnections: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setConnections } = slice.actions;
