import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import supportedConnectionsReducer from './slices/supportedConnections';
import loadingReducer from './slices/loading';
import connectionAccountMappingReducer from './slices/connectionAccountMapping';
import createAccountReducer from './slices/createAccount';
import userInfoReducer from './slices/userInfo';
import connectionReducer from './slices/connection';
import shopifyPopupReducer from './slices/shopify/popup';
import woocommercePopupReducer from './slices/woocommerce/popup';
import amazonPopupReducer from './slices/amazon/popup';
import connectionsReducer from './slices/connections';
import plansReducer from './slices/plans';
import cartReducer from './slices/cart';
import subscriptionsReducer from './slices/subscriptions';
import amazonCountryUrlsReducer from './slices/amazonCountryUrls';
import tallyFilesListReducer from './slices/tallyFilesList';

import analysisdataReducer from './slices/analysisdata';
import analysisdata2Reducer from './slices/analysisdata2';
import analysisfilteredresponseReducer from './slices/analysisfilteredresponse';
import EmailVerificationErrorReducer from './slices/EmailVerificationError';
import confirmPopupReducer from './slices/confirmPopup';
import ordersReducer from './slices/orders';
import userAccountMappingReducer from './slices/userAccountMapping';
import supportedFrequencyReducer from './slices/supportedFrequency';
import fetchHistoricalDataModalReducer from './slices/fetchHistoricalDataModal';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['paymentDetails']
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  supportedConnections: supportedConnectionsReducer,
  loading: loadingReducer,
  connectionAccountMapping: connectionAccountMappingReducer,
  createAccount: createAccountReducer,
  userInfo: userInfoReducer,
  connection: connectionReducer,
  shopifyPopup: shopifyPopupReducer,
  woocommercePopup: woocommercePopupReducer,
  amazonPopup: amazonPopupReducer,
  connections: connectionsReducer,
  plans: plansReducer,
  subscriptions: subscriptionsReducer,
  amazonCountryUrls: amazonCountryUrlsReducer,
  tallyFiles: tallyFilesListReducer,
  cart: cartReducer,
  product: persistReducer(productPersistConfig, productReducer),
  analysisdata: analysisdataReducer,
  analysisdata2: analysisdata2Reducer,
  analysisfilteredresponse: analysisfilteredresponseReducer,
  EmailVerificationError: EmailVerificationErrorReducer,
  confirmPopup: confirmPopupReducer,
  allOrders: ordersReducer,
  userAccountMapping: userAccountMappingReducer,
  supportedFrequency: supportedFrequencyReducer,
  fetchHistoricalDataModal: fetchHistoricalDataModalReducer
});

export { rootPersistConfig, rootReducer };
