import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const slice = createSlice({
  name: 'supportedConnectionsList',
  initialState,
  reducers: {
    setSupportedconnections: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setSupportedconnections } = slice.actions;
