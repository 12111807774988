import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
// components
import { PageTitle } from '../../../../../components/headings';
import { OrderCompleteIllustration } from '../../../../../assets';
// hooks
import useIsMountedRef from '../../../../../hooks/useIsMountedRef';
import useAxios from '../../../../../hooks/useAxios';
// actions
import { getGoogleAdsCustomers, saveGoogleAdsCustomers } from '../../../../../actions/connections';

export default function SelectGoogleCustomer() {
  const theme = useTheme();
  const [parentCustomerId, setParentCustomerId] = useState('');
  const [childCustomerIds, setChildCustomerIds] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  // get customers
  const axiosInstance = useAxios();
  const { data, isLoading, isError } = useQuery({
    queryKey: ['googleAdsCustomers'],
    queryFn: () => getGoogleAdsCustomers({ axiosInstance })
  });

  const mutation = useMutation({
    mutationFn: (data) => saveGoogleAdsCustomers({ axiosInstance, ...data })
  });

  if (isLoading) return <Typography variant="body2">Loading...</Typography>;
  if (isError)
    return (
      <Typography variant="body2" color="error">
        {submitError}
      </Typography>
    );

  const handleParentChange = (parentId) => {
    setParentCustomerId((prev) => {
      if (prev === parentId) {
        setChildCustomerIds([]);
        return '';
      }

      const custObj = data?.find((item) => item.rootCustomerClient.id === parentId);
      const children = custObj.cidToChildren;
      const childIds = children.map((child) => child.id);
      setChildCustomerIds(childIds);

      return parentId;
    });
  };
  const handleChildChange = (childId, parentId) => {
    if (parentId !== parentCustomerId) {
      setParentCustomerId(parentId);
      setChildCustomerIds([childId]);
    } else {
      setChildCustomerIds((prev) => {
        if (prev.includes(childId)) {
          return prev.filter((id) => id !== childId);
        }
        return [...prev, childId];
      });
    }
  };
  const handleSubmit = () => {
    if (!parentCustomerId || !childCustomerIds.length) {
      setSubmitError('Please select at least one id');
      return;
    }
    setSubmitLoading(true);
    setSubmitSuccess(false);
    mutation.mutate(
      { parentId: parentCustomerId, childIds: childCustomerIds },
      {
        onSuccess: () => {
          setSubmitLoading(false);
          setSubmitSuccess(true);
        },
        onError: () => {
          setSubmitLoading(false);
          setSubmitError('Something went wrong');
          setSubmitSuccess(false);
        }
      }
    );
  };
  const renderChildCustomers = (parentId) => {
    const custObj = data?.find((item) => item.rootCustomerClient.id === parentId);
    const children = custObj.cidToChildren;

    return (
      <Stack direction="column">
        {children.map((child) => (
          <FormControlLabel
            key={child.id}
            control={<Checkbox checked={childCustomerIds.includes(child.id)} />}
            label={`${child.descriptiveName} - ${child.id}`}
            onChange={(e) => handleChildChange(child.id, parentId)}
          />
        ))}
      </Stack>
    );
  };
  if (submitSuccess) {
    return (
      <Container>
        <PageTitle> Google Ads Configured successfully </PageTitle>
        <OrderCompleteIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
      </Container>
    );
  }
  return (
    <Container>
      <PageTitle> Select Google Ads Account </PageTitle>
      <FormControl fullWidth>
        {data?.map((item) => (
          <FormGroup>
            <Card sx={{ mt: 1 }}>
              <CardHeader
                title={
                  <FormControlLabel
                    control={<Checkbox checked={parentCustomerId === item.rootCustomerClient.id} />}
                    label={`MCC - ${item.rootCustomerClient.descriptiveName} - ${item.rootCustomerClient.id}`}
                    onChange={(e) => handleParentChange(item.rootCustomerClient.id)}
                  />
                }
                sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}
              />
              <CardContent>{renderChildCustomers(item.rootCustomerClient.id)}</CardContent>
            </Card>
          </FormGroup>
        ))}
      </FormControl>
      <LoadingButton variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSubmit} loading={submitLoading}>
        Submit
      </LoadingButton>
      {submitError && (
        <Typography variant="body2" color="error">
          {submitError}
        </Typography>
      )}
    </Container>
  );
}
