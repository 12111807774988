import { useState } from 'react';
import { useQuery } from '@apollo/client';
// material UI
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  Alert,
  Box,
  TextField,
  Button,
  Typography,
  Skeleton,
  InputLabel,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import { GET_AMAZON_ENDPOINT_CONFIG } from '../../../../../graphql/Queries';

import useAxios from '../../../../../hooks/useAxios';
import { GET_AMAZON_AUTH_URL } from '../../../../../backendUrl/apiUrl';
import { SectionHeading, CardTitle } from '../../../../../components/headings';

export default function SelectCountryForAmazon() {
  const [country, setCountry] = useState('');
  const { loading, error, data } = useQuery(GET_AMAZON_ENDPOINT_CONFIG);
  const axiosInstance = useAxios();
  if (loading) return <Skeleton variant="rectangular" height={400} width="auto" />;
  if (error)
    return (
      <Typography variant="body2" color="error.main">
        Something went wrong
      </Typography>
    );

  const fetchedData = data.amazonEndpointConfig;
  const handleChange = (e) => {
    setCountry(e.target.value);
  };
  const handleSubmit = async () => {
    await axiosInstance
      .get(GET_AMAZON_AUTH_URL, { params: { country } })
      .then((res) => {
        window.open(res.data, '_self');
      })
      .catch((err) => console.error('err', err));
  };
  return (
    <Card>
      <CardHeader
        title={<CardTitle>Select your country</CardTitle>}
        subheader={
          <Typography variant="body2" color="text.secondary">
            Select the country in which your shop is originally registered.
          </Typography>
        }
      />
      <CardContent>
        <Stack direction="column" spacing={3} alignItems="center">
          <FormControl fullWidth>
            <InputLabel id="country-select-label">Country</InputLabel>
            <Select
              labelId="country-select-label"
              id="country-select"
              value={country}
              label="Country"
              onChange={handleChange}
            >
              {fetchedData.map((data) => (
                <MenuItem value={data.country} key={data.country}>
                  {data.country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button sx={{ width: 164 }} variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
