import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = [];

const slice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setPlans } = slice.actions;
