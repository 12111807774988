import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// material UI
import { Typography, Skeleton, Container } from '@mui/material';
// GQL
import { useQuery } from '@apollo/client';
import { GET_CONNECTION_CONFIGURATION } from '../../../../graphql/Queries';
// Components
import Page from '../../../../components/Page';
import { DisplayNoDataError } from '../../../../utils/displayNoDataError';
import ComponentMapping from './componentMaps';
import { PageTitle } from '../../../../components/headings';

export default function ConnectApps() {
  const [appDetails, setAppDetails] = useState(null);
  const [step, setStep] = useState(null);

  const { appId } = useParams();
  const { loading, error, data } = useQuery(GET_CONNECTION_CONFIGURATION, {
    variables: { appId }
  });
  useEffect(() => {
    if (!loading && data && data.connectionConfiguration) setAppDetails(data.connectionConfiguration);
  }, [loading]);

  if (loading) return <Skeleton variant="rectangular" height={400} width="auto" />;
  if (error) return <p>Error :{error}</p>;

  if (!appDetails) return <DisplayNoDataError />;

  const renderComp = (appDetails) => {
    if (!appDetails && !appDetails.app) return null;
    const { invokeFunction } = appDetails.config[0];
    const CompToRender = ComponentMapping[invokeFunction] || null;
    return CompToRender ? <CompToRender /> : null;
  };

  return (
    <Page title="Connect Apps | Niblin">
      <Container maxWidth="xl">
        <PageTitle>Connect {appDetails.app ? appDetails.app.name : null}</PageTitle>

        {renderComp(appDetails)}
      </Container>
    </Page>
  );
}
