import { getRequest } from '../../../utils/axios';
import {
  GET_SUPPORTED_CONNECTIONS,
  GET_CONNECTIONS,
  GET_CART,
  GET_USER_SUBSCRIPTIONS
} from '../../../backendUrl/apiUrl';
import { setSupportedconnections } from '../../../redux/slices/supportedConnections';
import { setConnections } from '../../../redux/slices/connections';
import { setSubscriptions } from '../../../redux/slices/subscriptions';
import { setCart } from '../../../redux/slices/cart';
import { startLoading, stopLoading } from '../../../redux/slices/loading';

export const fetchCart = () => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id
  };
  return dispatch(getRequest(GET_CART, params)).then((response) => {
    dispatch(setCart(response.data.cart));
  });
};

export const getConnections = (isLoading) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id
  };
  return dispatch(getRequest(GET_CONNECTIONS, params, {}, isLoading)).then((res) => {
    dispatch(setConnections(res.data.connections));
  });
};

export const getUserData = () => (dispatch, getState) => {
  const { userInfo } = getState();

  const params = {
    user_id: userInfo._id
  };
  dispatch(startLoading());
  Promise.all([
    dispatch(getRequest(GET_SUPPORTED_CONNECTIONS, {}, {}, true)),
    // dispatch(getRequest(GET_CART, params, {}, true)),
    dispatch(getRequest(GET_CONNECTIONS, params, {}, true))
    // dispatch(getRequest(GET_USER_SUBSCRIPTIONS, params, {}, true))
  ])
    .then((response) => {
      dispatch(setSupportedconnections(response[0].data));
      // dispatch(setCart(response[1].data.cart));
      dispatch(setConnections(response[2].data.connections));
      // dispatch(setSubscriptions(response[3].data.subscriptions));
      dispatch(stopLoading());
    })
    .catch((error) => {
      console.error('error', error);
      dispatch(stopLoading());
    });
};
