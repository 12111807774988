import { CANCEL_SUBSCRIPTION, GET_USER_SUBSCRIPTIONS } from '../../../backendUrl/apiUrl';
import { setSubscriptions } from '../../../redux/slices/subscriptions';
import { showConfirmationPopup } from '../../../redux/slices/confirmPopup';
import { postRequest, getRequest } from '../../../utils/axios';

export const getUserSubsCription = (isLoading) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id
  };
  return dispatch(getRequest(GET_USER_SUBSCRIPTIONS, params, {}, isLoading)).then((res) => {
    dispatch(setSubscriptions(res.data.subscriptions));
  });
};

export const cancelUserSubsCription =
  (...args) =>
  (dispatch, getState) => {
    const { userInfo } = getState();
    const [subscriptionId] = args;
    const body = {
      user_id: userInfo._id,
      subscription_id: subscriptionId
    };
    dispatch(postRequest(CANCEL_SUBSCRIPTION, body)).then((res) => {
      dispatch(getUserSubsCription());
    });
  };

export const handleConfirmation =
  (...args) =>
  (dispatch) => {
    dispatch(
      showConfirmationPopup({
        onSubmit: cancelUserSubsCription,
        args,
        message: 'Are you sure to cancel subscription ?'
      })
    );
  };
