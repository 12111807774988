import { Box, Typography } from '@mui/material';

export function DisplayNoDataError({ message }) {
  if (!message) message = 'No data found';
  return (
    <Box>
      <Typography variant="body2" sx={{ color: 'error.main' }}>
        {message}
      </Typography>
    </Box>
  );
}
