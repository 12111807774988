import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShopifyShopNameVisible: false
};

const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showShopNameModal: (state) => ({ ...state, isShopifyShopNameVisible: true }),
    hideShopNameModal: (state) => ({ ...state, isShopifyShopNameVisible: false })
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showShopNameModal, hideShopNameModal } = slice.actions;
