import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// routes
import { PATH_PAGE, PATH_DASHBOARD } from '../routes/paths';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  if (
    searchParams.get('hmac') &&
    searchParams.get('host') &&
    searchParams.get('shop') &&
    searchParams.get('timestamp')
  ) {
    const formattedSearchParams = JSON.stringify([...searchParams.entries()]);
    localStorage.setItem('appstore_install_search_params', formattedSearchParams);
    localStorage.setItem('shop', searchParams.get('shop'));
    localStorage.setItem('isRedirect', true);
    localStorage.setItem('app', 'SHOPIFY');

    //    const hmac = searchParams.get('hmac');
    //    const host = searchParams.get('host');
    //    const shop = searchParams.get('shop');
    //    const timestamp = searchParams.get('timestamp');
    // navigate to validating page
    return <Navigate to={`${PATH_PAGE.validateAppRedirect}`} />;
  }

  return <>{children}</>;
}
