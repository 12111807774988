import { gql } from '@apollo/client';

const GET_DAILY_REFUND_NUMBER = gql`
  query {
    dailyRefundNumber {
      value
      series {
        name
        value
      }
    }
  }
`;
const GET_WEEKLY_REFUND_NUMBER = gql`
  query {
    weeklyRefundNumber {
      value
      series {
        name
        value
      }
    }
  }
`;
const GET_MONTHLY_REFUND_NUMBER = gql`
  query {
    monthlyRefundNumber {
      value
      series {
        name
        value
      }
    }
  }
`;

export { GET_DAILY_REFUND_NUMBER, GET_WEEKLY_REFUND_NUMBER, GET_MONTHLY_REFUND_NUMBER };
