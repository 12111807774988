import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setCart } = slice.actions;
