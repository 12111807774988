import { connect } from 'react-redux';
import Component from './Component';
import { getUserAccountMapping, showCreateAccount, handleSubmit } from './action';

const mapStateToProps = ({ connectionAccountMapping, loading, userAccountMapping }) => ({
  connectionAccountMapping,
  loading,
  userAccountMapping
});

const mapDispatchToProps = { getUserAccountMapping, showCreateAccount, handleSubmit };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
