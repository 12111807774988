import { gql } from '@apollo/client';

const GET_INVENTORY_SUMMARY = gql`
  query {
    skuSummary {
      productCount
      inStockCount
      outOfStockCount
    }
  }
`;

const GET_DOD_PRODUCTS_SOLD = gql`
  query ($from_date: String!, $to_date: String!) {
    dodProductsSold(from_date: $from_date, to_date: $to_date) {
      date
      quantity
    }
  }
`;

const GET_DAILY_RUN_RATE = gql`
  query ($from_date: String!, $to_date: String!) {
    dailyRunRate(from_date: $from_date, to_date: $to_date) {
      historicalRR
      currentRR
      percentageChange
    }
  }
`;

const GET_SKU_MOVEMENT = gql`
  query {
    skuMovement {
      productsSold
      productsNotSold
      stuckInventory
    }
  }
`;

const GET_ALL_SKU_ANALYTICS = gql`
  query {
    allSkuAnalytics {
      id
      stockLevel
      movingAverage
      orderCount
      product {
        id
        name
        sku
      }
    }
  }
`;

export { GET_DOD_PRODUCTS_SOLD, GET_INVENTORY_SUMMARY, GET_DAILY_RUN_RATE, GET_SKU_MOVEMENT, GET_ALL_SKU_ANALYTICS };
