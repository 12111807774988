import {
  Home as HomeIcon,
  Stream as StreamIcon,
  Loyalty as LoyaltyIcon,
  AccountTree as AccountTreeIcon,
  AdsClick as AdsClickIcon,
  Group as GroupIcon,
  Inventory as InventoryIcon,
  Sell as SellIcon,
  ShoppingCart as ShoppingCartIcon,
  Person as PersonIcon,
  BarChart as BarChartIcon,
  Anchor as AnchorIcon,
  PeopleAlt as PeopleAltIcon,
  SafetyDivider as SafetyDividerIcon,
  RecentActors as RecentActorsIcon,
  AccessTimeFilled as AccessTimeFilledIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import Typography from '@mui/material/Typography';
// routes
import { PATH_DASHBOARD, PATH_SETTINGS } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';
// Non-Local icons

// ----------------------------------------------------------------------

/*
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);
const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  connections: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};
*/

const NONLOCALICONS = {
  home: <HomeIcon />,
  connections: <StreamIcon />,
  subscriptions: <LoyaltyIcon />,
  category: <AccountTreeIcon />,
  channel: <AdsClickIcon />,
  inventory: <InventoryIcon />,
  product: <SellIcon />,
  orders: <ShoppingCartIcon />,
  user: <PersonIcon />,
  analytics: <BarChartIcon />,
  retention: <AnchorIcon />,
  customers: <PeopleAltIcon />,
  segment: <SafetyDividerIcon />,
  userAnalytics: <RecentActorsIcon />,
  rfm: <AccessTimeFilledIcon />,
  settings: <SettingsIcon />
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Home',
        path: PATH_DASHBOARD.home.dod,
        icon: NONLOCALICONS.home
      },
      // old home location is PATH_DASHBOARD.general.app
      // { title: 'subscriptions', path: PATH_DASHBOARD.general.subscription, icon: NONLOCALICONS.subscriptions },
      // { title: 'pricing', path: PATH_DASHBOARD.general.pricing, icon: ICONS.banking },
      // { title: 'home', path: PATH_DASHBOARD.general.home, icon: NONLOCALICONS.home },
      //      { title: 'Sales Analytics', path: PATH_DASHBOARD.salesAnalytics.root, icon: NONLOCALICONS.analytics },
      //     { title: 'Category Analytics', path: PATH_DASHBOARD.categoryAnalytics.root, icon: NONLOCALICONS.category },
      { title: 'Channel Analytics', path: PATH_DASHBOARD.channelAnalytics.root, icon: NONLOCALICONS.channel },
      //       { title: 'Customer Analytics', path: PATH_DASHBOARD.customerAnalytics.root, icon: NONLOCALICONS.userAnalytics },
      //    { title: 'Inventory Analytics', path: PATH_DASHBOARD.inventoryAnalytics.root, icon: NONLOCALICONS.inventory },
      {
        title: 'Customer Segmentation Tool',
        path: PATH_DASHBOARD.customerSegmentation.root,
        icon: NONLOCALICONS.segment
      },
      { title: 'Recency, Frequency and Monetary (RFM)', path: PATH_DASHBOARD.rfm.root, icon: NONLOCALICONS.rfm },
      { title: 'Customer Retention', path: PATH_DASHBOARD.retention.root, icon: NONLOCALICONS.retention },
      { title: 'All Customers', path: PATH_DASHBOARD.customers.root, icon: NONLOCALICONS.customers },
      { title: 'Products', path: PATH_DASHBOARD.products.root, icon: NONLOCALICONS.product },
      { title: 'Orders', path: PATH_DASHBOARD.orders.root, icon: NONLOCALICONS.orders },
      { title: 'Connections', path: PATH_DASHBOARD.connections.root, icon: NONLOCALICONS.connections }
      // { title: 'GeneralApp', path: PATH_DASHBOARD.general.app, icon: NONLOCALICONS.connections }
      // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
      // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
    ]
  },
  {
    subheader: 'Account Mangement',
    items: [
      {
        title: 'Settings',
        path: PATH_SETTINGS.root,
        icon: NONLOCALICONS.settings
      }
    ]
  }

  // MANAGEMENT
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account }
  //       ]
  //     },

  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
  //       ]
  //     },

  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
  //       ]
  //     }
  //   ]
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: <Label color="error">2</Label>
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     {
  //       title: 'kanban',
  //       path: PATH_DASHBOARD.kanban,
  //       icon: ICONS.kanban
  //     }
  //   ]
  // }
];

export default sidebarConfig;
