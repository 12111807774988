import { postRequest, getRequest } from '../../../../../../../utils/axios';
import { CREATE_XERO_ACCOUNT, GET_XERO_ACCOUNTS } from '../../../../../../../backendUrl/apiUrl';
import { hideCreateAccountModal } from '../../../../../../../redux/slices/createAccount';
import { setConnectionAccountMapping } from '../../../../../../../redux/slices/connectionAccountMapping';

export const handleCreateAccount =
  (values, { resetForm }, supportedConnection, setError, setSuccess) =>
  (dispatch, getState) => {
    const {
      connectionAccountMapping: { transactionTypes },
      userInfo
    } = getState();
    const body = {
      name: values.name,
      code: values.code,
      type: values.type,
      description: values.description
    };

    const params = {
      user_id: userInfo?._id,
      app_id: supportedConnection?.to_app_id
    };
    console.log('params..................', params);
    dispatch(postRequest(CREATE_XERO_ACCOUNT, body, {}, params))
      .then((res) => {
        const accountsReq = {
          user_id: userInfo._id,
          app_id: supportedConnection.to_app_id
        };

        dispatch(getRequest(GET_XERO_ACCOUNTS, accountsReq, {}))
          .then((res) => {
            resetForm();
            dispatch(setConnectionAccountMapping({ transactionTypes, accounts: res?.data?.accounts }));
            setSuccess(true);
            setError(false);
            setTimeout(() => {
              dispatch(hideCreateAccountModal('xero'));
            }, 5000);
          })
          .catch(() => {
            setSuccess(false);
            setError(true);
          });
      })
      .catch(() => {
        setSuccess(false);
        setError(true);
      });
  };
