import { connect } from 'react-redux';
import Component from './Component';
import { handleDownload, getAllTallyFiles, getTallyFile } from './action';

const mapStateToProps = ({ loading, tallyFiles }) => ({
  loading,
  tallyFiles
});

const mapDispatchToProps = { handleDownload, getAllTallyFiles, getTallyFile };

export default connect(mapStateToProps, mapDispatchToProps)(Component);
