// material
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Autocomplete, TextField, Box, Stack, IconButton } from '@mui/material';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import { Icon } from '@iconify/react';

// components
import Page from '../../../../../components/Page';
import LoadingScreen from '../../../../../components/LoadingScreen';
import CreateXeroAccount from './components/CreateXeroAccount';
import CreateQuickBooksAccount from './components/CreateQuickBooksAccount';

// ----------------------------------------------------------------------

export default function ConnectionConfigurationScrren({
  getUserAccountMapping,
  connectionAccountMapping,
  showCreateAccount,
  loading,
  handleSubmit,
  userAccountMapping
}) {
  const [mappingDetails, setMappingDetails] = useState([]);

  const {
    state: { supportedConnection, connection }
  } = useLocation();

  const navigate = useNavigate();

  const handleChange = (value, transactionTypeObj) => {
    const foundIndex = mappingDetails.findIndex((x) => x.transactionTypeID === transactionTypeObj.id);
    if (foundIndex !== -1) {
      const fileteredArray = mappingDetails.filter((item) => item.transactionTypeID !== transactionTypeObj.id);
      setMappingDetails([
        ...fileteredArray,
        {
          transactionTypeID: transactionTypeObj.id,
          accountInfo: value
        }
      ]);
    } else {
      setMappingDetails([
        ...mappingDetails,
        {
          transactionTypeID: transactionTypeObj.id,
          accountInfo: value
        }
      ]);
    }
  };
  useEffect(() => {
    getUserAccountMapping(connection, supportedConnection);
  }, []);
  useEffect(() => {
    if (userAccountMapping?.mapping?.length > 0) {
      setMappingDetails(userAccountMapping?.mapping);
    }
  }, [userAccountMapping?.mapping]);
  return (
    <>
      <CreateXeroAccount supportedConnection={supportedConnection} />
      <CreateQuickBooksAccount supportedConnection={supportedConnection} />
      <Page title=" Configure App | Niblin">
        {loading ? (
          <Grid container justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
            <Grid item>
              <LoadingScreen />
            </Grid>
          </Grid>
        ) : (
          <Box maxWidth={610} sx={{ margin: 'auto', padding: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row">
                <IconButton onClick={() => navigate(-1)}>
                  <Icon icon={arrowIosBackFill} width={30} height={30} />
                </IconButton>
                <Typography variant="h5" sx={{ mt: 0.8 }}>
                  {connection?.connections_configured_status ? 'Edit accounts mapping' : 'Create accounts mapping'}
                </Typography>
              </Stack>

              <Button variant="outlined" onClick={() => showCreateAccount(supportedConnection)}>
                + Create Account
              </Button>
            </Stack>
            {connectionAccountMapping?.transactionTypes?.length > 0 && connectionAccountMapping?.accounts?.length > 0 && (
              <Box sx={{ pl: 5.2 }}>
                <Grid container spacing={2} sx={{ mt: 3 }}>
                  <Grid item xs={6} md={4} sx={{ mb: 4 }}>
                    <Typography variant="h6">Transaction Type</Typography>
                  </Grid>
                  <Grid item xs={6} md={8} sx={{ mb: 4 }}>
                    <Typography variant="h6">Account Name</Typography>
                  </Grid>
                  {connectionAccountMapping?.transactionTypes?.map((transactionTypesObj) => {
                    const userAccMapping = userAccountMapping?.mapping?.find(
                      (mapping) => mapping?.transactionTypeID === transactionTypesObj?.id
                    );
                    return (
                      <>
                        <Grid item xs={6} md={4}>
                          <Typography>{` ${transactionTypesObj.name} `}</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          {userAccMapping?.accountInfo ? (
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              autoHighlight
                              onChange={(event, newValue) => {
                                handleChange(newValue, transactionTypesObj);
                              }}
                              disableClearable
                              defaultValue={userAccMapping?.accountInfo}
                              options={connectionAccountMapping?.accounts}
                              getOptionLabel={(option) =>
                                ` ${option?.Code ? `${option.Code} - ` : null}${option?.Name} `
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Select an existing account" variant="standard" />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              id="free-solo-demo"
                              freeSolo
                              autoHighlight
                              onChange={(event, newValue) => {
                                handleChange(newValue, transactionTypesObj);
                              }}
                              disableClearable
                              options={connectionAccountMapping?.accounts}
                              getOptionLabel={(option) =>
                                ` ${option?.Code ? `${option.Code} - ` : null}${option?.Name} `
                              }
                              renderInput={(params) => (
                                <TextField {...params} label="Select an existing account" variant="standard" />
                              )}
                            />
                          )}
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <Grid item xs={12} md={12} sx={{ mt: 4 }}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={() => {
                      handleSubmit(mappingDetails, connection, supportedConnection, navigate);
                    }}
                    disabled={mappingDetails.length !== connectionAccountMapping?.transactionTypes?.length}
                  >
                    Next
                  </Button>
                </Grid>
              </Box>
            )}
          </Box>
        )}
      </Page>
    </>
  );
}
