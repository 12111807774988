import { gql } from '@apollo/client';

const GET_SUPPORTED_CONNECTIONS = gql`
  query {
    supportedConnections {
      id
      status
      fromApp {
        _id
        name
        logo
      }
      toApp {
        _id
        name
        logo
      }
    }
  }
`;

const GET_CONNECTION_CONFIGURATION = gql`
  query ($appId: String!) {
    connectionConfiguration(appId: $appId) {
      id
      app {
        name
        logo
      }
      config {
        step
        name
        title
        invokeFunction
      }
    }
  }
`;

const GET_CONNECTION_DETAILS = gql`
  query ($appInfoId: String!) {
    connectionDetails(appInfoId: $appInfoId) {
      app {
        name
        logo
        identifier
      }
      shopName
      status
      frequency {
        interval
        title
      }
      metaData {
        shopId
        shopName
        url
      }
    }
  }
`;

const GET_AMAZON_ENDPOINT_CONFIG = gql`
  query {
    amazonEndpointConfig {
      country
      prodApiUrl
      sandboxApiUrl
    }
  }
`;

const GET_FREQ_WITH_SUB_STATUS = gql`
  query {
    allowedFrequencies {
      _id
      interval
      title
      subscription_status
    }
  }
`;
const GET_CONNECTION_CANDIDATES = gql`
  query {
    connectionCandidates {
      app {
        _id
        name
        logo
        identifier
      }
      connectionStatus
    }
  }
`;

export {
  GET_SUPPORTED_CONNECTIONS,
  GET_CONNECTION_CONFIGURATION,
  GET_CONNECTION_DETAILS,
  GET_AMAZON_ENDPOINT_CONFIG,
  GET_FREQ_WITH_SUB_STATUS,
  GET_CONNECTION_CANDIDATES
};
