import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isXeroCreateAccountVisible: false,
  isQuickBooksCreateAccountVisible: false
};

const slice = createSlice({
  name: 'createAccount',
  initialState,
  reducers: {
    showCreateAccountModal: (state, action) => {
      switch (action.payload) {
        case 'xero':
          return {
            ...state,
            isXeroCreateAccountVisible: true
          };
        case 'quickbooks':
          return {
            ...state,
            isQuickBooksCreateAccountVisible: true
          };
        default:
          return state;
      }
    },
    hideCreateAccountModal: (state, action) => {
      switch (action.payload) {
        case 'xero':
          return {
            ...state,
            isXeroCreateAccountVisible: false
          };
        case 'quickBooks':
          return {
            ...state,
            isQuickBooksCreateAccountVisible: false
          };
        default:
          return state;
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { showCreateAccountModal, hideCreateAccountModal } = slice.actions;
