import { GENERATE_TALLY_FILE, GET_ALL_TALLY_FILES, GET_TALLY_FILE } from '../../../../../backendUrl/apiUrl';
import { getRequest } from '../../../../../utils/axios';
import { setTallyFiles } from '../../../../../redux/slices/tallyFilesList';

export const handleDownload = (fromDate, toDate) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id,
    min_date: fromDate.toISOString().split('T')[0],
    max_date: toDate.toISOString().split('T')[0]
  };
  dispatch(getRequest(GENERATE_TALLY_FILE, params)).then((res) => {
    const data = res.data.tally_xml_file;
    const fileName = `${res.data.min_date.split('T')[0]}__${res.data.max_date.split('T')[0]}`;
    const pom = document.createElement('a');
    const bb = new Blob([data], { type: 'text/xml' });

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', fileName);

    pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');

    pom.click();
  });
};

export const getAllTallyFiles = () => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id
  };
  dispatch(getRequest(GET_ALL_TALLY_FILES, params, undefined, true)).then((res) => {
    const finalResponse = res.data.map((item) => ({
      ...item,
      min_date: item.min_date.split('T')[0],
      max_date: item.max_date.split('T')[0]
    }));
    dispatch(setTallyFiles(finalResponse));
  });
};

export const getTallyFile = (values, setLoadingFile) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id,
    id: values._id
  };
  setLoadingFile(true);
  dispatch(getRequest(GET_TALLY_FILE, params, undefined, true)).then((res) => {
    const data = res.data.tally_xml_file;
    const fileName = `${res.data.min_date.split('T')[0]}__${res.data.max_date.split('T')[0]}`;
    const pom = document.createElement('a');
    const bb = new Blob([data], { type: 'text/xml' });

    pom.setAttribute('href', window.URL.createObjectURL(bb));
    pom.setAttribute('download', fileName);

    pom.dataset.downloadurl = ['text/xml', pom.download, pom.href].join(':');
    pom.draggable = true;
    pom.classList.add('dragout');
    pom.click();
  });
};
