import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Stack,
  Button,
  TextField,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Alert,
  Grid,
  Typography,
  Box,
  LinearProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import Lottie from 'react-lottie-player';

const getInitialValues = () => {
  const account = {
    name: '',
    code: '',
    type: '',
    description: ''
  };

  return account;
};

const CreatXeroAccount = ({
  createAccount,
  handleCreateAccount,
  hideCreateAccountModal,
  loading,
  supportedConnection
}) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const EventSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    code: Yup.string().required('Code is required'),
    type: Yup.string().required('Type is required')
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: EventSchema,
    onSubmit: async (values, props) => {
      handleCreateAccount(values, props, supportedConnection, setError, setSuccess);
    }
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <Dialog fullWidth open={createAccount?.isXeroCreateAccountVisible} maxWidth="xs" scroll="paper" onClose={() => {}}>
      <DialogTitle>Create Xero Account</DialogTitle>
      {error ? (
        <Box sx={{ mt: 3, ml: 5, mr: 5 }}>
          <Alert severity="error">Something went wrong</Alert>
        </Box>
      ) : (
        ''
      )}
      {success ? (
        <Grid container justifyContent="center" alignItems="center" sx={{ mt: 5 }}>
          <Grid item>
            <Box sx={{ ml: 5 }}>
              <Lottie
                loop
                path="https://assets2.lottiefiles.com/packages/lf20_owom3yeb.json"
                play
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
                style={{ width: 100, height: 100 }}
              />
            </Box>
            <Box sx={{ mb: 20 }}>
              <Typography>Account created successfully</Typography>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid sx={{ mt: 3 }}>
              <LinearProgress color="primary" />
            </Grid>
          ) : (
            ''
          )}
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="standard"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    fullWidth
                    label="Code"
                    variant="standard"
                    {...getFieldProps('code')}
                    error={Boolean(touched.code && errors.code)}
                    helperText={touched.code && errors.code}
                  />
                  <TextField
                    fullWidth
                    label="Type"
                    {...getFieldProps('type')}
                    variant="standard"
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                  />
                  <TextField fullWidth label="Description" {...getFieldProps('description')} variant="standard" />
                </Stack>
              </DialogContent>

              <DialogActions>
                <Button type="button" variant="outlined" color="inherit" onClick={() => hideCreateAccountModal('xero')}>
                  Cancel
                </Button>
                <LoadingButton type="submit" variant="contained" loading={loading} loadingIndicator="Loading...">
                  Create
                </LoadingButton>
              </DialogActions>
            </Form>
          </FormikProvider>
        </>
      )}
    </Dialog>
  );
};

export default CreatXeroAccount;
