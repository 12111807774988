import { useAuth0 } from '@auth0/auth0-react';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Link, Container, Button, Typography, Tooltip } from '@mui/material';

import { useLocation } from 'react-router-dom';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import OnboardingForm from './onboardingForm';
// import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 640,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function CompanyDetailsPage({ userStatusRefetch }) {
  const { logout } = useAuth0();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const preAuth = searchParams.get('preAuth');

  return (
    <RootStyle title="Entry Company settings | Niblin">
      <Container>
        <ContentStyle>
          <Box sx={{ mb: 5, display: 'flex', alignItems: 'center' }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Enter company settings
              </Typography>
              {/* <Typography sx={{ color: 'text.secondary' }}>Free forever. No credit card needed.</Typography> */}
            </Box>
            <Box
              component="img"
              src="https://storage.googleapis.com/niblin-public-images/logo_transparent.png"
              sx={{ width: 80, height: 80, resize: 'contain' }}
            />
          </Box>

          <OnboardingForm preAuth={preAuth} userStatusRefetch={userStatusRefetch} />
          <br />
          <Button onClick={() => logout()}>Logout </Button>

          <br />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
