import { gql } from '@apollo/client';

const GET_CUSTOMER_SUMMARY = gql`
  query {
    customerOverallSummary {
      totalCustomers
      repeatCustomers
      newCustomers
    }
  }
`;
const GET_AOV_ALL_CUSTOMERS = gql`
  query ($from_date: String!, $to_date: String!) {
    aovForAllCustomers(from_date: $from_date, to_date: $to_date)
  }
`;
const GET_AOV_NEW_CUSTOMERS = gql`
  query ($from_date: String!, $to_date: String!) {
    aovForNewCustomers(from_date: $from_date, to_date: $to_date)
  }
`;
const GET_AOV_REPEAT_CUSTOMERS = gql`
  query ($from_date: String!, $to_date: String!) {
    aovForRepeatCustomers(from_date: $from_date, to_date: $to_date)
  }
`;
const GET_HIGH_VALUE_CUSTOMERS = gql`
  query {
    highValueCustomers {
      id
      name
      email
      phone
      orderCount
      salesTotal
    }
  }
`;
const GET_CUSTOMERS_WITH_DETAILS = gql`
  query {
    customersWithDetails {
      id
      name
      phone
      email
      orderCount
      salesTotal
      firstOrderDate
      daysSinceFirstOrder
      lastOrderDate
      daysSinceLastOrder
      averageTimeBetweenOrders
      aov
    }
  }
`;
const GET_NUM_CHURN_RISK_CUSTOMERS = gql`
  query {
    numChurnRiskCustomers
  }
`;
const GET_NUM_CHURNED_CUSTOMERS = gql`
  query {
    numChurnedCustomers
  }
`;
const GET_APP_WISE_CUSTOMERS_COUNT = gql`
  query ($from_date: String!, $to_date: String!) {
    appWiseCustomersCount(from_date: $from_date, to_date: $to_date) {
      customersCount
      app {
        id
        name
        logo
      }
    }
  }
`;

const GET_NEW_VS_OLD_CUSTOMERS_ORDERS_DOD = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    newVsReturningCustomersOrdersDOD(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        date
      }
      new {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
      returning {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;
const GET_NEW_VS_OLD_CUSTOMERS_ORDERS_WOW = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    newVsReturningCustomersOrdersWOW(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      dateObj {
        week
        year
      }
      new {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
      returning {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;
const GET_NEW_VS_OLD_CUSTOMERS_ORDERS_MOM = gql`
  query ($fromDate: String, $toDate: String) {
    newVsReturningCustomersOrdersMOM(fromDate: $fromDate, toDate: $toDate) {
      dateObj {
        month
        year
      }
      new {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
      returning {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;
const GET_RFM_ANLAYTICS = gql`
  query {
    rfmAnalytics {
      rfmSegment
      count
      avgSalesTotal
      totalSalesValue
      avgOrderCount
    }
  }
`;

export {
  GET_CUSTOMER_SUMMARY,
  GET_AOV_ALL_CUSTOMERS,
  GET_AOV_NEW_CUSTOMERS,
  GET_AOV_REPEAT_CUSTOMERS,
  GET_HIGH_VALUE_CUSTOMERS,
  GET_CUSTOMERS_WITH_DETAILS,
  GET_NUM_CHURN_RISK_CUSTOMERS,
  GET_NUM_CHURNED_CUSTOMERS,
  GET_APP_WISE_CUSTOMERS_COUNT,
  GET_NEW_VS_OLD_CUSTOMERS_ORDERS_DOD,
  GET_NEW_VS_OLD_CUSTOMERS_ORDERS_WOW,
  GET_NEW_VS_OLD_CUSTOMERS_ORDERS_MOM,
  GET_RFM_ANLAYTICS
};
