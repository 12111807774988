import { gql } from '@apollo/client';

const GET_DAILY_ORDER_NUMBER = gql`
  query {
    dailyOrderNumber {
      value
      series {
        name
        value
      }
    }
  }
`;
const GET_WEEKLY_ORDER_NUMBER = gql`
  query {
    weeklyOrderNumber {
      value
      series {
        name
        value
      }
    }
  }
`;
const GET_MONTHLY_ORDER_NUMBER = gql`
  query {
    monthlyOrderNumber {
      value
      series {
        name
        value
      }
    }
  }
`;

const GET_ORDERS = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    orders(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      id
      order_id
      sales_total
      app {
        id
        name
        identifier
      }
      customer {
        id
        name
        email
      }
      order_created_at
    }
  }
`;
const GET_ORDER = gql`
  query ($id: String!) {
    order(id: $id) {
      id
      order_id
      order_created_at
      sales_total
      app {
        id
        name
      }
      order_status {
        status
        created_at
      }
      line_items {
        id
        product_variant {
          id
          name
          sku
          price
        }
      }
      customer {
        id
        name
        phone
      }
      discounts {
        id
        code
        type
        amount
      }
    }
  }
`;
export { GET_DAILY_ORDER_NUMBER, GET_WEEKLY_ORDER_NUMBER, GET_MONTHLY_ORDER_NUMBER, GET_ORDERS, GET_ORDER };
