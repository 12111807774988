import { gql, useQuery } from '@apollo/client';

const GET_ALL_CHANNELS = gql`
  query {
    channels {
      id
      name
      logo
      identifier
    }
  }
`;
const GET_CHANNEL_SUMMARY = gql`
  query ($id: String!, $from_date: String, $to_date: String) {
    channelSummary(id: $id, from_date: $from_date, to_date: $to_date) {
      aov
      salesTotal
      orderCount
    }
  }
`;
const GET_TOP_PRODUCTS_IN_CHANNEL = gql`
  query ($id: String!, $from_date: String, $to_date: String) {
    topSellingProductsInChannel(id: $id, from_date: $from_date, to_date: $to_date) {
      salesTotal
      product {
        id
        name
      }
    }
  }
`;
const GET_TOP_CATEGORIES_IN_CHANNEL = gql`
  query ($id: String!, $from_date: String, $to_date: String) {
    topSellingCategoriesInChannel(id: $id, from_date: $from_date, to_date: $to_date) {
      salesTotal
      category {
        name
      }
    }
  }
`;
const GET_DOD_SALES_IN_CHANNEL = gql`
  query ($id: String!, $from_date: String, $to_date: String) {
    dodSales(id: $id, from_date: $from_date, to_date: $to_date) {
      date
      salesTotal
    }
  }
`;
const GET_CHANNELS_WITH_ORD_SUMMARY = gql`
  query ($from_date: String, $to_date: String) {
    channelsWithOrdersSummary(from_date: $from_date, to_date: $to_date) {
      id
      app {
        name
      }
      salesTotal
      orderCount
      aov
    }
  }
`;
const GET_TOP_CUSTOMERS_IN_CHANNEL = gql`
  query ($id: String!, $from_date: String, $to_date: String) {
    topCustomersInChannel(id: $id, from_date: $from_date, to_date: $to_date) {
      id
      salesTotal
      orderCount
      customer {
        id
        name
        phone
        email
      }
    }
  }
`;

const GET_ALL_CHANNELS_DOD_SUMMARY = gql`
  query ($fromDate: String, $toDate: String, $channelIdentifier: String) {
    allChannelsDodSummary(fromDate: $fromDate, toDate: $toDate, channelIdentifier: $channelIdentifier) {
      id
      channel {
        id
        name
        logo
        identifier
      }
      salesDistribution {
        dateObj {
          date
          year
        }
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;

const GET_ALL_CHANNELS_WOW_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    allChannelsWowSummary(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        id
        name
        logo
      }
      salesDistribution {
        dateObj {
          year
          week
        }
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;

const GET_ALL_CHANNELS_MOM_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    allChannelsMomSummary(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        id
        name
        logo
      }
      salesDistribution {
        dateObj {
          year
          month
        }
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
        discountValue
      }
    }
  }
`;
const GET_CHANNEL_CUST_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    channelCustomersSummaryDOD(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        id
        name
        logo
      }
      new {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
      }
      returning {
        gmv
        netmv
        orderCount
        netaov
        totalItemsSold
        itemCountPerOrder
        netAsp
      }
    }
  }
`;

const GET_ALL_CHANNELS_CITIES_SALES = gql`
  query ($fromDate: String, $toDate: String) {
    channelCitiesSalesDist(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        id
        name
        logo
      }
      citySummary {
        city
        netmv
        orderCount
      }
    }
  }
`;
export {
  GET_ALL_CHANNELS,
  GET_ALL_CHANNELS_CITIES_SALES,
  GET_ALL_CHANNELS_DOD_SUMMARY,
  GET_ALL_CHANNELS_MOM_SUMMARY,
  GET_ALL_CHANNELS_WOW_SUMMARY,
  GET_CHANNEL_CUST_SUMMARY,
  GET_CHANNEL_SUMMARY,
  GET_CHANNELS_WITH_ORD_SUMMARY,
  GET_DOD_SALES_IN_CHANNEL,
  GET_TOP_CATEGORIES_IN_CHANNEL,
  GET_TOP_CUSTOMERS_IN_CHANNEL,
  GET_TOP_PRODUCTS_IN_CHANNEL
};
