import { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, Container, Alert, Stack, IconButton, Card, Divider } from '@mui/material';
import { Icon } from '@iconify/react';
import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import CircleRounded from '@mui/icons-material/CircleRounded';
import { styled, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import ShopifyShopNamePopup from './components/ShopifyShopNamePopup';
import AmazonCountryListPopup from './components/AmazonCountryListPopup';
import WoocomerceStoreURLPoup from './components/WoocomerceStoreURLPoup';
import LoadingScreen from '../../../../../components/LoadingScreen';
import Page from '../../../../../components/Page';
import { getActiveSubscription } from '../../../../../utils/pricing';
import { PATH_DASHBOARD } from '../../../../../routes/paths';

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 600,
  margin: 'auto',
  padding: theme.spacing(1),
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(4)
  }
}));

export default function AppConnection({
  handleAppConnect,
  getAppConnection,
  connection,
  getConnections,
  handleConfigureNow,
  subscriptions,
  getUserSubsCription
}) {
  const {
    state: { supportedConnection, isRedirection }
  } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  useEffect(async () => {
    setLoading(true);
    await getAppConnection(supportedConnection);
    if (isRedirection) {
      await getConnections(true);
    }
    await getUserSubsCription(true);
    setLoading(false);
  }, []);

  const activeSubscription = getActiveSubscription(subscriptions);
  return (
    <>
      <ShopifyShopNamePopup supportedConnection={supportedConnection} appId={supportedConnection.from_app_id} />
      <AmazonCountryListPopup supportedConnection={supportedConnection} appId={supportedConnection.from_app_id} />
      <WoocomerceStoreURLPoup supportedConnection={supportedConnection} appId={supportedConnection.from_app_id} />
      <Page title=" Connnect App | Niblin">
        <Container sx={{ mt: 7 }}>
          {loading ? (
            <Grid container justifyContent="center" alignItems="center" sx={{ mt: 15 }}>
              <Grid item>
                <LoadingScreen />
              </Grid>
            </Grid>
          ) : (
            <RootStyle>
              {activeSubscription?.length <= 0 && (
                <Box>
                  <Alert severity="info">
                    You haven't subscribed any plan
                    <Button
                      type="button"
                      variant="outlined"
                      size="small"
                      sx={{ ml: 3 }}
                      onClick={() => navigate(PATH_DASHBOARD.general.pricing)}
                    >
                      {' '}
                      explore plan
                    </Button>
                  </Alert>
                </Box>
              )}
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row">
                  <IconButton onClick={() => navigate(-1)}>
                    <Icon icon={arrowIosBackFill} width={30} height={30} />
                  </IconButton>
                  <Typography variant="h5" sx={{ mt: 0.8 }}>
                    Connect app
                  </Typography>
                </Stack>
                {connection?.connections_established_status === 'Both' && (
                  <Button
                    type="button"
                    size="medium"
                    sx={{ color: PRIMARY_MAIN }}
                    onClick={() => handleConfigureNow(navigate, supportedConnection, connection)}
                    disabled={activeSubscription?.length <= 0}
                  >
                    {connection.connections_configured_status ? 'Edit connection' : 'Configure connection'}
                  </Button>
                )}
              </Stack>

              <Box sx={{ pl: 6, pr: 3, pt: 3, pb: 3 }}>
                <Typography variant="h6"> Step 1</Typography>
                <Typography variant="body1"> Connect your e-commerce software</Typography>
                <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mt: 1 }}>
                  <Box>
                    <img
                      alt={supportedConnection.from_app_name}
                      src={supportedConnection?.from_app_logo}
                      style={{ width: 40, height: 40, resize: 'contain' }}
                    />
                  </Box>

                  {connection?.connections_established_status === 'From' ||
                  connection?.connections_established_status === 'Both' ? (
                    <Box display="flex" flexDirection="row" sx={{ mt: 2.5 }}>
                      <CircleRounded color="primary" sx={{ pr: 0.4, pt: 0.8 }} fontSize="12px" />
                      <Typography>Connected</Typography>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center" alignContent="center">
                      <Button
                        type="button"
                        variant="outlined"
                        size="medium"
                        disabled={activeSubscription?.length <= 0}
                        onClick={() =>
                          handleAppConnect(
                            supportedConnection,
                            supportedConnection.from_app_name,
                            supportedConnection.from_app_id
                          )
                        }
                        sx={{ mt: 2 }}
                      >
                        Connect
                      </Button>
                    </Box>
                  )}
                </Box>
                <Box sx={{ mb: 3, mt: 3 }}>
                  <Divider />
                </Box>
                <Box>
                  <Typography variant="h6"> Step 2</Typography>
                  <Typography variant="body1"> Connect your accounting software</Typography>
                  <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ mt: 1 }}>
                    <Box>
                      <img
                        alt={supportedConnection.to_app_name}
                        src={supportedConnection?.to_app_logo}
                        style={{ width: 40, height: 40, resize: 'contain' }}
                      />
                    </Box>

                    {(connection && connection.connections_established_status === 'To') ||
                    (connection && connection.connections_established_status === 'Both') ? (
                      <Box display="flex" flexDirection="row" sx={{ mt: 2.5 }}>
                        <CircleRounded color="primary" sx={{ pr: 0.4, pt: 0.8 }} fontSize="12px" />
                        <Typography>Connected</Typography>
                      </Box>
                    ) : (
                      <Box display="flex" justifyContent="center" alignContent="center">
                        <Button
                          type="button"
                          variant="outlined"
                          size="medium"
                          disabled={activeSubscription?.length <= 0}
                          onClick={() =>
                            handleAppConnect(
                              supportedConnection,
                              supportedConnection.to_app_name,
                              supportedConnection.to_app_id
                            )
                          }
                          sx={{ mt: 2 }}
                        >
                          Connect
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </RootStyle>
          )}
        </Container>
      </Page>
    </>
  );
}
