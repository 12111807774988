import { createSlice } from '@reduxjs/toolkit';

const initialState = { analysisdata: [] };

const slice = createSlice({
  name: 'analysisfilteredresponse',
  initialState,
  reducers: {
    setanalysisfilteredresponse: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setanalysisfilteredresponse } = slice.actions;
