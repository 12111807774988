import { GET_CONNECTION_DETAILS } from '../../../../../backendUrl/apiUrl';
import { getRequest } from '../../../../../utils/axios';

export const getConnectionDetails = (supportedConnection, connection) => (dispatch, getState) => {
  const { userInfo } = getState();
  const params = {
    user_id: userInfo._id,
    connection_id: connection._id,
    from_app_id: supportedConnection.from_app_id
  };
  return dispatch(getRequest(GET_CONNECTION_DETAILS, params)).then((res) => res.data);
};
