import { gql } from '@apollo/client';

const GET_DISCOUNT_CODE_DIST = gql`
  query {
    discountCodeDist {
      code
      totalDiscount
      usageCount
    }
  }
`;
const GET_DOD_CHANNEL_DISCOUNT_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    dodChannelDiscountSummary(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        name
      }
      discountCodeDist {
        code
        totalDiscount
        usageCount
      }
    }
  }
`;
const GET_WOW_CHANNEL_DISCOUNT_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    wowChannelDiscountSummary(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        name
      }
      discountCodeDist {
        code
        totalDiscount
        usageCount
      }
    }
  }
`;
const GET_MOM_CHANNEL_DISCOUNT_SUMMARY = gql`
  query ($fromDate: String, $toDate: String) {
    momChannelDiscountSummary(fromDate: $fromDate, toDate: $toDate) {
      id
      channel {
        name
      }
      discountCodeDist {
        code
        totalDiscount
        usageCount
      }
    }
  }
`;

export {
  GET_DISCOUNT_CODE_DIST,
  GET_DOD_CHANNEL_DISCOUNT_SUMMARY,
  GET_WOW_CHANNEL_DISCOUNT_SUMMARY,
  GET_MOM_CHANNEL_DISCOUNT_SUMMARY
};
