import { createSlice } from '@reduxjs/toolkit';
// utils

// ----------------------------------------------------------------------

const initialState = {};

const slice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    // START LOADING
    setUser: (state, action) => action.payload
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { setUser } = slice.actions;
